import React from 'react';
import { Paper, TextField } from '@material-ui/core';
import DatePicker from 'react-datepicker';
import { withStyles } from '@material-ui/core/styles';

import 'react-datepicker/dist/react-datepicker.css';

const styles = (theme) => ({
  day: {
    cursor: 'pointer',
  },
  container: {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.body1.fontSize,
    zIndex: '5000',
  },
});

const SuperReactDatePicker = (props) => {
  const { classes, textFieldFullWidth, ...otherProps } = props;

  return (
    <DatePicker
      showPopperArrow
      popperPlacement="bottom-start"
      customInput={<TextField fullWidth={textFieldFullWidth} margin="dense" />}
      calendarClassName={classes.container}
      calendarContainer={({ className, children }) => (
        <Paper elevation={4} className={`${className}`}>
          <div className="react-datepicker__triangle"></div>
          {children}
        </Paper>
      )}
      dayClassName={(date) => classes.day}
      {...otherProps}
    />
  );
};

export default withStyles(styles)(SuperReactDatePicker);

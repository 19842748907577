const endpoints = {
  //announcements
  getAnnouncements: '/api/dtm/announcements/get',
  registerAnnouncements: '/api/dtm/announcements/register',

  pcr: '/api/wo/pcr',
  location: '/api/dt/places',
  drivers: '/api/wo/drivers',
  method: '/api/dt/method_list',
  route_summary_detail: '/api/wo/route_summary_detail',
  routing_summary: '/api/wo/routing_summary',
  update_bucket_workorder: '/api/wo/update_bucket_workorder',
};

export default endpoints;

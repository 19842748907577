import { Grid, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { lookups_ac, setTitle_ac } from '../../actions/me.ac.js';
import { CompletedcompletedDocsListing } from './CompletedAccountingDocsListing';
import { FBAccountingDocsLabelling } from './FBAccountingDocLabelling.js';
import { FBAccountingDocListing } from './FBAccountingDocListing.js';
import { FBUploadFiles } from './FBUploadFiles';

const styles = (theme) => ({
  root: {
    width: '100%',
    overflowY: 'auto',
    overflowX: 'auto',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing.unit * 2,
    height: 'calc(100%)',
    backgroundColor: '#fbf8f896',
    overflowY: 'auto',
    display: 'flex',
    flexDirection: 'column',
  },
  scrollArea: {
    overflowY: 'auto',
    '-webkitOverflowScrolling': 'touch',
    height: '100%',
  },
  fullHeight: {
    height: '100%',
  },
  pdfviewer: {
    flexGrow: 1,
    width: '100%',
    height: '100%',
    overflowY: 'auto',
  },
  pageStatement: {
    paddingTop: 0,
    fontSize: 12,
  },
  details: {
    fontSize: '.92em',
    margin: '2px 8px 2px 0',
    padding: '2px 8px 2px 0',
  },
});

class AccountingIndexinglanding extends Component {
  componentDidMount() {
    const { setTitle_ac } = this.props;
    setTitle_ac('Accounting Docs Indexing');
    this.checkSituation();
  }

  componentDidUpdate() {
    this.checkSituation();
  }

  checkSituation() {
    const { lookups, me } = this.props;
    if (!me.user) return;
    if (lookups.ink === null) {
      if (lookups.isFetching) return;

      return lookups_ac();
    }
  }

  render() {
    const { classes, fileBound, me } = this.props;
    const { selectedAccountingDoc, isFetchingDetails } = fileBound;
    const leftHandGridSize = selectedAccountingDoc ? 3 : 6;
    const rightHandGridSize = selectedAccountingDoc ? 9 : 6;
    const leftGridTitle = selectedAccountingDoc
      ? 'Accounting Docs Ready For Indexing'
      : 'Pending Accounting Docs';
    const rightGridTitle = selectedAccountingDoc
      ? 'PDF Viewer'
      : 'Completed Accounting Docs';

    if (
      !me.user.roles.includes('fba-indexer') &&
      !me.user.roles.includes('it-admin')
    ) {
      return (
        <Grid container spacing={8} item className={classes.fullHeight}>
          <Grid item xs={12} sm={12} md={12} className={classes.fullHeight}>
            <main
              id="fbAccountingDocLabellingParentPopper"
              className={classes.content}>
              You do not have access to this feature
            </main>
          </Grid>
        </Grid>
      );
    }

    return (
      <Grid container spacing={8} item className={classes.fullHeight}>
        <Grid item xs={12} sm={12} md={12} className={classes.fullHeight}>
          <main
            id="fbAccountingDocLabellingParentPopper"
            className={classes.content}>
            <Grid container direction="row" spacing={8}>
              <Grid item xs={leftHandGridSize}>
                <Typography
                  className={classes.pageStatement}
                  color="textSecondary">
                  {leftGridTitle}
                </Typography>
              </Grid>
              <Grid item xs={rightHandGridSize}>
                <Typography
                  className={classes.pageStatement}
                  color="textSecondary">
                  {rightGridTitle}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              direction="row"
              spacing={8}
              className={classes.fullHeight}>
              <Grid xs={leftHandGridSize} item className={classes.fullHeight}>
                {!selectedAccountingDoc && <FBAccountingDocListing />}
                {selectedAccountingDoc && <FBAccountingDocsLabelling />}
              </Grid>
              <Grid xs={rightHandGridSize} item className={classes.fullHeight}>
                {!selectedAccountingDoc && <FBUploadFiles />}
                {!selectedAccountingDoc && <CompletedcompletedDocsListing />}
                {selectedAccountingDoc && (
                  <div className={classes.pdfviewer}>
                    {isFetchingDetails && <strong>...loading pdf...</strong>}

                    {!isFetchingDetails && selectedAccountingDoc.signedUrl && (
                      <embed
                        type="application/pdf"
                        src={selectedAccountingDoc.signedUrl + '#zoom=50'}
                        marginWidth="0"
                        style={{ width: '99%', height: 'calc(100% - 8px)' }}
                      />
                    )}
                  </div>
                )}
              </Grid>
            </Grid>
          </main>
        </Grid>
      </Grid>
    );
  }
}

AccountingIndexinglanding.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (stateFromStore) => ({
  me: stateFromStore.me,
  lookups: stateFromStore.lookups,
  fileBound: stateFromStore.fileBound,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setTitle_ac,
      lookups_ac,
    },
    dispatch
  );

export const FBAccountingIndexingLanding = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles)(AccountingIndexinglanding))
);

import React from 'react';
import { Chip, Grid, Typography } from '@material-ui/core';

const LineTypeChip = ({ text, icon: Icon, bgColor, style, onClick }) => {
  return (
    <Chip
      label={
        <Grid style={{ height: 24 }}>
          <Icon fontSize="12px" />
          <Typography
            inline
            style={{
              paddingLeft: 3,
              color: 'white',
              fontWeight: 'bold',
              fontSize: 10,
            }}>
            {text}
          </Typography>
        </Grid>
      }
      style={{
        width: 30,
        height: 15,
        fontSize: 14,
        marginLeft: 5,
        color: 'white',
        background: bgColor,
        ...style,
      }}
      onClick={onClick}
    />
  );
};

export default LineTypeChip;

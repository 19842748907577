import { Grid, Typography } from '@material-ui/core';
import _ from 'lodash';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDrop } from 'react-dnd';

import { withDnDSupport } from '../../../hoc/dnd';
import SummaryCard from './SummaryCard';

const SummaryLocationTab = (props) => {
  const originalData = useRef([]);
  const [cards, setCards] = useState([]);

  const { data, setWo, recentMoveWo } = props;

  useEffect(() => {
    setCards(data);
    originalData.current = _.cloneDeep(data);
  }, [data]);

  const moveCard = useCallback(
    (sourceGroupIndex, sourceIndex, targetGroupIndex, targetIndex) => {
      let movedItem = null;
      let targetGroup = null;

      // Update cards state
      setCards((prevCards) => {
        const updatedCards = _.cloneDeep(prevCards);

        // Ensure source group exists and has WOs
        const sourceGroup = updatedCards[sourceGroupIndex];
        if (!sourceGroup?.wos?.wos) return updatedCards;

        // Ensure target group exists and has WOs
        targetGroup = updatedCards[targetGroupIndex];

        // Ensure target group exists and has WOs
        if (!targetGroup?.wos.wos) {
          targetGroup.wos = { wos: [] }; // If not, initialize wos as an empty array
        }
        // Remove item from source group
        [movedItem] = sourceGroup.wos.wos.splice(sourceIndex, 1);

        // Add item to target group
        targetGroup.wos.wos.splice(targetIndex, 0, movedItem);

        // Return updated cards
        return updatedCards;
      });

      // After the cards update, now update wo state
      if (movedItem && targetGroup) {
        setWo((prev) => [
          ...prev,
          {
            wo_id: movedItem._id,
            targetGroupId: targetGroup.group_id,
            movedItem,
          },
        ]);
      }
    },
    [setWo]
  );

  const wasCardMoved = (groupIndex, cardId) => {
    // Find the card's original group and check if it matches the current group
    for (let i = 0; i < originalData.current.length; i++) {
      const originalGroup = originalData.current[i];
      if (originalGroup?.wos?.wos.some((wo) => wo._id === cardId)) {
        return i !== groupIndex;
      }
    }
    return false;
  };

  const renderCard = useCallback(
    (groupIndex, data, index) => {
      const recentlyMoved = wasCardMoved(groupIndex, data._id);
      return (
        <SummaryCard
          key={`${groupIndex}-${index}`}
          index={index}
          id={data._id}
          data={data}
          groupIndex={groupIndex}
          moveCard={moveCard}
          recentlyMoved={recentMoveWo ? recentlyMoved : recentMoveWo}
        />
      );
    },
    [moveCard, recentMoveWo]
  );

  const DropPlaceholder = ({ groupIndex }) => {
    const [, drop] = useDrop({
      accept: 'card',
      drop: (item) => {
        const targetGroupIndex = groupIndex;
        const targetGroup = cards[targetGroupIndex];
        const targetIndex = targetGroup?.wos?.wos?.length || 0;
        if (item) {
          moveCard(
            item.item.groupIndex,
            item.item.index,
            targetGroupIndex,
            targetIndex
          );
        }
      },
    });

    return (
      <div
        ref={drop}
        style={{
          padding: 20,
          width: '100%',
          textAlign: 'center',
          backgroundColor: '#f1f1f1',
          border: '2px dashed #ccc',
        }}>
        <Typography variant="body2" style={{ color: '#888' }}>
          Drop here to add
        </Typography>
      </div>
    );
  };

  return (
    <Grid
      container
      spacing={16}
      style={{
        overflowY: 'scroll',
        height: 550,
      }}>
      {cards.map((group, groupIndex) => (
        <Grid key={groupIndex} item xs={3}>
          <Grid
            container
            spacing={16}
            justify="space-between"
            alignItems="center"
            style={{ cursor: 'pointer' }}
            onClick={() => {
              const params = group?.wos?.wos.map((wo) => wo._id);
              props.history.push('/dt', params);
            }}>
            <Grid item style={{ padding: 10 }}>
              <Typography inline style={{ fontWeight: 'bold' }}>
                {group?.group_nm} ({group?.group_id})
              </Typography>
            </Grid>
            <Grid item style={{ padding: 10 }}>
              <Typography style={{ fontWeight: 'bold' }}>
                {group?.wos?.wos?.length}
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={8}>
            {group?.wos?.wos?.length > 0 ? (
              group.wos.wos.map((data, i) => renderCard(groupIndex, data, i))
            ) : (
              <DropPlaceholder groupIndex={groupIndex} />
            )}
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
};

export default withDnDSupport(SummaryLocationTab);

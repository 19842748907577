export async function fetchJotformQuestions(
  formId,
  { before, after, onSuccess, onError } = {}
) {
  if (before) before();
  try {
    const response = await window.sch.post('/api/jotform/get_form_questions', {
      formId,
    });

    if (response.error) {
      console.error(response.error);
      if (onError) onError(new Error(response.error));
    }

    if (onSuccess) onSuccess(response?.questions || []);
    else return response?.questions;
  } catch (err) {
    if (onError) onError(err);
    console.error(err);
  }

  if (after) after();
}

export async function fetchTemplateMappings({
  before,
  after,
  onSuccess,
  onError,
} = {}) {
  if (before) before();
  try {
    const response = await window.sch.post('/api/jotform/mappings/get', {
      includeDisabled: true,
    });

    if (response.error) {
      console.error(response.error);
      if (onError) onError(new Error(response.error));
    }

    if (onSuccess) onSuccess(response?.mappings || []);
    else return response?.mappings;
  } catch (err) {
    if (onError) onError(err);
    console.error(err);
  }

  if (after) after();
}

export async function upsertTemplateMapping(
  data,
  { before, after, onSuccess, onError } = {}
) {
  if (before) before();
  try {
    const response = await window.sch.post(
      '/api/jotform/mappings/upsert',
      data
    );

    if (response.error) {
      console.error(response.error);
      if (onError) onError(new Error(response.error));
    }

    if (onSuccess) onSuccess(response?.mapping || {});
    else return response?.mapping || {};
  } catch (err) {
    if (onError) onError(err);
    console.error(err);
  }

  if (after) after();
}

export async function deleteTemplateMapping(
  formId,
  { before, after, onSuccess, onError } = {}
) {
  if (before) before();
  try {
    const response = await window.sch.post('/api/jotform/mappings/remove', {
      formId,
    });

    if (response.error) {
      console.error(response.error);
      if (onError) onError(new Error(response.error));
    }

    if (onSuccess) onSuccess(response?.message || '');
    else return response?.message || '';
  } catch (err) {
    if (onError) onError(err);
    console.error(err);
  }

  if (after) after();
}

export async function setTemplateActivation(
  formId,
  disable,
  { before, after, onSuccess, onError } = {}
) {
  if (before) before();
  try {
    const response = await window.sch.post('/api/jotform/mappings/activation', {
      formId,
      disable,
    });

    if (response.error) {
      console.error(response.error);
      if (onError) onError(new Error(response.error));
    }

    if (onSuccess) onSuccess(response?.message || '');
    else return response?.message || '';
  } catch (err) {
    if (onError) onError(err);
    console.error(err);
  }

  if (after) after();
}

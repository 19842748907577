import React, { useEffect, useMemo, useRef } from 'react';
import './timeline.css';

// items = [{ lbl, ts, active }] -> [{ content, time, className }]
const Timeline = (props) => {
  const { items = [], style } = props;

  const scrollOlRef = useRef(null);

  const [parsedItems, activeItemIndex] = useMemo(() => {
    let passed = true;
    return [
      items.map((it) => {
        if (it.active) passed = false;
        return {
          content: it.label,
          // time: moment(it.ts).format('llll'),
          className: it.active ? 'active' : passed ? 'passed' : 'pending',
        };
      }),
      items.findIndex((it) => it.active),
    ];
  }, [items]);

  // Scroll into view
  useEffect(() => {
    if (activeItemIndex > -1) {
      scrollOlRef.current.children[activeItemIndex].scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'start',
      });
    }
  }, [activeItemIndex]);

  return (
    <div className={'horizontal_timeline'} style={style}>
      <ol ref={scrollOlRef}>
        {parsedItems.map((it, idx) => (
          <li className={it.className} key={idx}>
            {/* <span className="time">{it.time}</span> */}
            <span className="content">{it.content}</span>
          </li>
        ))}
      </ol>
    </div>
  );
};

export default Timeline;

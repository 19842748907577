import CssBaseline from '@material-ui/core/CssBaseline';
import { orange, purple } from '@material-ui/core/colors';
import {
  MuiThemeProvider,
  createMuiTheme,
  withStyles,
} from '@material-ui/core/styles';
import { push } from 'connected-react-router';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { Route, withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { emit_df_activity_ac } from './actions/room.ac';
import { default as ActivePatientsLanding } from './components/ActivePatients/ActivePatientsLanding.js';
import ActiveRentalsLanding from './components/ActiveRentalsLanding';
import ActivityLogAudit from './components/ActivityLog';
import AdjRequestsReportLanding from './components/AdjustmentsReport/AdjRequestsReportLanding';
import AllScriptsReportRouter from './components/AllScriptsReport/AllScriptsReportRouter.js';
import AppealRequestsReportLanding from './components/AppealRequestsReport/AppealRequestsReportLanding';
import AxiomLanding from './components/Axiom';
import CatalogMaintainance from './components/CatalogMaintainance/CatalogMaintainanceLanding';
import ClientAppsLanding from './components/ClientApps/ClientAppsLanding.js';
import ConfirmationLogLanding from './components/ConfirmationLog/ConfirmationLogLanding';
import ConnectHoldsLanding from './components/ConnectHolds/ConnectHoldsLanding';
import DFWorkunitsReportsLanding from './components/DFWorkunitsReports/DFWorkunitsReportsLanding';
import ReportByDateAndHours from './components/DFWorkunitsReports/DateAndHoursReport.js';
import DTTools from './components/DTrack/DTTools';
import {
  DeliveryTrack,
  DeliveryTrackSummary,
} from './components/DeliveryTrack';
import AccountToIdnLanding from './components/Dfa/AccountToIdnLanding';
import { DfaAppBar, MobileDfaAppBar } from './components/Dfa/DfaAppBar';
import DfaLanding from './components/Dfa/DfaLanding';
import DocKeyToIdnLanding from './components/Dfa/DocKeyToIdnLanding';
import GeneralLanding from './components/Dfa/GeneralLanding';
import DocRequestsLanding from './components/DocRequest/DocRequestsLanding';
import ExpiringPAsLanding from './components/ExpiringPAs/ExpiringPAsLanding';
import ExpiringPAsReportLanding from './components/ExpiringPAs/ExpiringPAsReportLanding';
import { FBAccountingLanding } from './components/FBSearch/FBAccountingLanding';
import { FBPatientLanding } from './components/FBSearch/FBPatientLanding';
import { FBAccountingIndexingLanding } from './components/FileBound/FBAccountingIndexingLanding';
import GapiNotValidPage from './components/GapiNotValidPage';
import Home from './components/Home';
import { HourlyWosListView } from './components/HourlyWos';
import IdnShellLanding from './components/IdnShell/IdnShellLanding.js';
import MailBoxesLanding from './components/MailBoxes/MailBoxesLanding.js';
import MappingToolLanding from './components/MappingTool/MappingToolLanding.js';
import MednecIssueLanding from './components/MednecIssueLog/MednecIssueLanding';
import NotLoggedIn from './components/NotLoggedIn';
import CareplansLanding from './components/OneOffs/CareplansLanding';
import CorrespondenceLockboxList from './components/OneOffs/CorrespondenceLockboxList';
import CreateManualTicket from './components/OneOffs/CreateManualTicket';
import DevOnly from './components/OneOffs/DevOnly';
import DownloadFaxLanding from './components/OneOffs/DownloadFaxLanding.js';
import IdnCreateLanding from './components/OneOffs/IdnCreateLanding';
import LookupsLanding from './components/OneOffs/LookupsLanding';
import NotifyStats from './components/OneOffs/NotifyStats';
import PricingCalc from './components/OneOffs/PricingCalc';
import PulseRedirect from './components/OneOffs/PulseRedirect';
import OpenCountsLanding from './components/OpenCounts/OpenCountsLanding';
import OpsFormsReportLanding from './components/OpsFormsReport/OpsFormsReportLanding';
import OxygenCounts from './components/OxygenCounts';
import PaErrorsLanding from './components/PAErrors/PaErrorsLanding';
import { PAPCalculatorLanding } from './components/PAPCalculator/PAPCalculatorLanding';
import PDFGenDrawer from './components/PDFGen/PDFGenDrawer';
import ParachuteReportRouter from './components/Parachute/ParachuteReportRouter';
import PatientLettersReportLanding from './components/PatientLettersReport/PatientLettersReportLanding';
import PatientPortalRegistrationsLanding from './components/PatientPortalRegs/PatientPortalRegistrationLanding';
import PlansOfServiceLanding from './components/PlansOfService/PlansOfServiceLanding';
import AdminRBACLanding from './components/RBAC';
import CareConnectReports from './components/Reports/CareConnect';
import ComplianceReport from './components/Reports/ComplianceReport';
import ExpiringCCSMCalReportLanding from './components/Reports/ExpiringCCSMCalReportLanding';
import ExpiringCMNsReportBySituation from './components/Reports/ExpiringCMNsReportBySituation';
import ExpiringCMNsReportLanding from './components/Reports/ExpiringCMNsReportLanding';
import GSOSerialReport from './components/Reports/GSOSerialReport';
import KilledItems from './components/Reports/KilledItems';
import OnboardedReport from './components/Reports/OnboardedReport';
import OpenOrdersTrackingReportLanding from './components/Reports/OpenOrdersTrackingReportLanding';
import RecurringReport from './components/Reports/Recurring';
import WosPaChecks from './components/Reports/WosPaChecks';
import { SNLookupLanding } from './components/SNLookup/SNLookupLanding';
import SalesDashLanding from './components/SalesDash/SalesDashLanding';
import SalesRepLanding from './components/SalesRep/SalesRepLanding';
import Loader from './components/Shared/Loader.js';
import SiteMapLanding from './components/SiteMap/SiteMapLanding';
import DCsLanding from './components/Teams/DCsLanding';
import TeamsLanding from './components/Teams/TeamsLanding';
import {
  TodosLandingNew,
  TodosLandingSimple,
} from './components/Todos/TodosLandingNew';
import {
  MobileTodosLandingSingle,
  TodosLandingSingle,
} from './components/Todos/TodosLandingSingle';
import { TodosLandingTable } from './components/Todos/TodosLandingTable';
import TodosSummaryLanding from './components/Todos/TodosSummaryLanding';
import UBLanding from './components/UB/UBLanding';
import UBStatsLanding from './components/UB/UBStatsLanding';
import MyTeams from './components/Users/MyTeams';
import TeamsGridLanding from './components/Users/TeamsGridLanding.js';
import WorkOrdersReportsLanding from './components/WorkOrders/WorkOrdersReportsLanding';
import PdfViewer from './components/pdfViewer/PdfViewer';
// import DFSnapshotsLanding from './components/Reports/DFSnapshotsLanding';
// import OpenOrdersLanding from './components/Reports/OpenOrdersLanding';
// import UserAdmin from './components/Users/UserAdmin';
// import WorkunitsLanding from './components/WorkUnits/workunitsLanding';
// import WorkunitsMobileLanding from './components/WorkUnits/workunitsMobileLanding';
// import CareConnectLanding from './components/CareConnect/CareConnectLanding';
// import ConnectDrawer from './components/Connect/ConnectDrawer';
// import { DTrackLanding } from './components/DTrack/DTrackLanding';
// import { WorkOrder } from './components/DTrack/WorkOrder';
// import IdnLanding from './components/Dfa/IdnLanding';
// import IdnMobileLanding from './components/Dfa/IdnMobileLanding';
// import FixedAssetAdminLanding from './components/FixedAssetAdmin';
// import FormsLanding from './components/FormsModule/FormsLanding';
import GSOTracking from './components/GSO/Tracking';
// import IndexingLanding from './components/Indexing/IndexingLanding';
// import IndexingAdminLanding from './components/IndexingAdmin/IdxAdminLanding';
import { AnnouncementForm, Announcements } from './components/Announcement';
import { ConnectedConsentDirectLanding as ConsentDirectLanding } from './components/Consent/ConsentForm';
import SummaryDetail from './components/DeliveryTrack/SummaryDetail';
import { DriversList, EditDriverForm } from './components/IndexingAdmin/Dtrack';
import JotformLanding from './components/JotformLanding/JotformLanding.js';
import RoutingSummary from './components/DeliveryTrack/RoutingSummary';

// Lazy loadings
const IndexingLanding = React.lazy(() =>
  import('./components/Indexing/IndexingLanding')
);
const IndexingAdminLanding = React.lazy(() =>
  import('./components/IndexingAdmin/IdxAdminLanding')
);
const WorkunitsLanding = React.lazy(() =>
  import('./components/WorkUnits/workunitsLanding')
);
const WorkunitsMobileLanding = React.lazy(() =>
  import('./components/WorkUnits/workunitsMobileLanding')
);
const FormsLanding = React.lazy(() =>
  import('./components/FormsModule/FormsLanding')
);
const IdnLanding = React.lazy(() => import('./components/Dfa/IdnLanding'));
const IdnMobileLanding = React.lazy(() =>
  import('./components/Dfa/IdnMobileLanding')
);
const FixedAssetAdminLanding = React.lazy(() =>
  import('./components/FixedAssetAdmin')
);
const UserAdmin = React.lazy(() => import('./components/Users/UserAdmin'));
const DFSnapshotsLanding = React.lazy(() =>
  import('./components/Reports/DFSnapshotsLanding')
);
const OpenOrdersLanding = React.lazy(() =>
  import('./components/Reports/OpenOrdersLanding')
);

// These two have no default exports so we have to use the object destructuring
const DTrackLanding = React.lazy(() =>
  import('./components/DTrack/DTrackLanding').then((module) => ({
    default: module.DTrackLanding,
  }))
);
const WorkOrder = React.lazy(() =>
  import('./components/DTrack/WorkOrder').then((module) => ({
    default: module.WorkOrder,
  }))
);

// const ConsentDirectLanding = React.lazy(() =>
//   import('./components/Consent/ConsentForm').then((module) => ({
//     default: module.ConsentDirectLanding,
//   }))
// );

const theme = createMuiTheme({
  palette: {
    primary: purple,
    secondary: orange,
  },
  typography: {
    useNextVariants: true,
    fontSize: 12,
  },
});
const styles = (theme) => ({
  '@global': {
    '*::-webkit-scrollbar': {
      width: '0.4em',
    },
    '*::-webkit-scrollbar-track': {
      '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)',
    },
    '*::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0,0,0,.1)',
      outline: '1px solid slategrey',
      backgroundClip: 'padding-box',
    },
  },
  root: {
    display: 'flex',
  },
  title: {
    flexGrow: 1,
  },
  topContent: {
    flexGrow: 1,
    // padding: `4px ${theme.spacing.unit * 2}px`,
    padding: `4px ${theme.spacing.unit}px`,
    backgroundColor: '#fff',
    overflowY: 'hidden',
  },
  withHeader: {
    marginTop: 56,
    height: 'calc(100vh - 56px)',
  },
  withoutHeader: {
    height: '100vh',
  },
  spacerOnly: {
    marginTop: 40,
  },
  content: {
    marginTop: 0,
    flexGrow: 1,
    padding: theme.spacing.unit * 2,
    height: '92vh',
    backgroundColor: '#fbf8f896',
    overflowY: 'hidden',
  },
});

class App extends React.Component {
  componentDidMount() {
    const p = _.get(this.props, 'history.location.pathname', false);
    if (p) {
      if (this.props.room.last_location !== p) {
        return this.props.emit_df_activity_ac(p);
      }
    }
  }

  componentDidUpdate() {
    const p = _.get(this.props, 'history.location.pathname', false);
    if (p) {
      if (this.props.room.last_location !== p) {
        return this.props.emit_df_activity_ac(p);
      }
    }
  }

  render() {
    const { classes, me, location, lookups } = this.props;
    if (!me.user) {
      return (
        <div>
          <main>
            {location.pathname !== '/alt_login' && (
              <NotLoggedIn someProp="true" />
            )}
          </main>
        </div>
      );
    }

    if (false === lookups.isGapiValid) {
      return (
        <main>
          <GapiNotValidPage />
        </main>
      );
    }

    let showAppBar =
      this.props.location.pathname.search(
        /single_workunit|mobile|no_app_bar/
      ) === -1 && this.props.location.search.search(/no_app_bar=true/) === -1;

    let useMobileAppBar = this.props.location.pathname.search(/mobile/) !== -1;

    return (
      <div>
        {/* <ConnectDrawer /> */}
        <PDFGenDrawer />

        <CssBaseline />
        <MuiThemeProvider theme={theme}>
          <div className={classes.root}>
            <Route
              exact
              path="/*"
              render={(routeProps) =>
                showAppBar ? (
                  <DfaAppBar />
                ) : useMobileAppBar ? (
                  <MobileDfaAppBar />
                ) : null
              }
            />

            <main
              className={
                showAppBar || useMobileAppBar
                  ? [classes.topContent, classes.withHeader].join(' ')
                  : [classes.topContent, classes.withoutHeader].join(' ')
              }>
              <Route exact path="/axiom/contracts" component={AxiomLanding} />

              <Route
                exact
                path="/reports/activitylog/audit"
                component={ActivityLogAudit}
              />

              <Route
                exact
                path="/teamsgrid/:tabName"
                component={TeamsGridLanding}
              />
              <Route
                exact
                path="/teamsgrid/:tabName/:subTabName"
                component={TeamsGridLanding}
              />
              <Route
                exact
                path="/idx/:tabName"
                // component={IndexingLanding}
                render={(routeProps) => (
                  <React.Suspense
                    fallback={<Loader type="circular" align="center" />}>
                    <IndexingLanding {...routeProps} />
                  </React.Suspense>
                )}
              />
              <Route
                exact
                path="/idx/:tabName/:subTabName"
                // component={IndexingLanding}
                render={(routeProps) => (
                  <React.Suspense
                    fallback={<Loader type="circular" align="center" />}>
                    <IndexingLanding {...routeProps} />
                  </React.Suspense>
                )}
              />
              <Route
                exact
                path="/idx/:tabName/:subTabName/:tabValue"
                // component={IndexingLanding}
                render={(routeProps) => (
                  <React.Suspense
                    fallback={<Loader type="circular" align="center" />}>
                    <IndexingLanding {...routeProps} />
                  </React.Suspense>
                )}
              />
              <Route
                exact
                path="/df"
                // component={WorkunitsLanding}
                render={(routeProps) => (
                  <React.Suspense
                    fallback={<Loader type="circular" align="center" />}>
                    <WorkunitsLanding {...routeProps} />
                  </React.Suspense>
                )}
              />
              <Route
                path={['/df/:tabName/:subTabName', '/df/:tabName']}
                // component={WorkunitsLanding}
                render={(routeProps) => (
                  <React.Suspense
                    fallback={<Loader type="circular" align="center" />}>
                    <WorkunitsLanding {...routeProps} />
                  </React.Suspense>
                )}
              />

              <Route
                exact
                path="/dfm"
                // component={WorkunitsMobileLanding}
                render={(routeProps) => (
                  <React.Suspense
                    fallback={<Loader type="circular" align="center" />}>
                    <WorkunitsMobileLanding {...routeProps} />
                  </React.Suspense>
                )}
              />
              <Route
                path={['/dfm/:tabName/:subTabName', '/dfm/:tabName']}
                // component={WorkunitsMobileLanding}
                render={(routeProps) => (
                  <React.Suspense
                    fallback={<Loader type="circular" align="center" />}>
                    <WorkunitsMobileLanding {...routeProps} />
                  </React.Suspense>
                )}
              />

              <Route
                path="/admin/idx/:tabName"
                // component={IndexingAdminLanding}
                render={(routeProps) => (
                  <React.Suspense
                    fallback={<Loader type="circular" align="center" />}>
                    <IndexingAdminLanding {...routeProps} />
                  </React.Suspense>
                )}
              />
              <Route path="/admin/dtrack/drivers" component={DriversList} />
              <Route
                path="/admin/dtrack/create-driver"
                component={EditDriverForm}
              />
              <Route
                path="/admin/dtrack/driver/:driver_id"
                component={EditDriverForm}
              />

              <Route
                exact
                path="/forms"
                //  component={FormsLanding}
                render={(routeProps) => (
                  <React.Suspense
                    fallback={<Loader type="circular" align="center" />}>
                    <FormsLanding {...routeProps} />
                  </React.Suspense>
                )}
              />

              <Route exact path="/jotform" component={JotformLanding} />

              <Route
                exact
                path="/forms/:tabName"
                // component={FormsLanding}
                render={(routeProps) => (
                  <React.Suspense
                    fallback={<Loader type="circular" align="center" />}>
                    <FormsLanding {...routeProps} />
                  </React.Suspense>
                )}
              />

              <Route
                exact
                path="/forms/:tabName/:subTabName"
                // component={FormsLanding}
                render={(routeProps) => (
                  <React.Suspense
                    fallback={<Loader type="circular" align="center" />}>
                    <FormsLanding {...routeProps} />
                  </React.Suspense>
                )}
              />

              <Route path="/sales/:tabName" component={SalesRepLanding} />

              <Route exact path="/admin/rbac" component={AdminRBACLanding} />

              <Route
                exact
                path="/admin/connect-holds"
                component={ConnectHoldsLanding}
              />
              <Route
                path="/admin/connect-holds/:tabName"
                component={ConnectHoldsLanding}
              />

              <Route exact path="/lookups" component={LookupsLanding} />
              <Route
                exact
                path="/create_manual_ticket"
                component={CreateManualTicket}
              />
              <Route exact path="/pricingcalc" component={PricingCalc} />
              <Route exact path="/dev_only" component={DevOnly} />
              <Route
                exact
                path="/correspondence"
                component={CorrespondenceLockboxList}
              />
              <Route
                exact
                path="/reports/notifications"
                component={NotifyStats}
              />
              <Route
                exact
                path="/pulse/appeals/:packet_action/:pulse_code"
                component={PulseRedirect}
              />
              <Route
                exact
                path="/direct_download/:faxId"
                component={DownloadFaxLanding}
              />
              <Route exact path="/careplans" component={CareplansLanding} />
              <Route
                exact
                path="/reports/parachute"
                component={ParachuteReportRouter}
              />
              <Route
                path={'/reports/parachute_new'}
                component={ParachuteReportRouter}
              />
              <Route
                exact
                path="/idn_create/:acctMember"
                component={IdnCreateLanding}
              />

              <Route
                exact
                path="/plans_of_service"
                component={PlansOfServiceLanding}
              />

              <Route
                exact
                path="/reports/killed_items"
                component={KilledItems}
              />
              <Route
                exact
                path="/reports/compliance"
                component={ComplianceReport}
              />
              <Route
                exact
                path="/reports/wos/hourly"
                component={HourlyWosListView}
              />
              <Route
                exact
                path="/reports/df_snapshots/:start/:end/:teams"
                // component={DFSnapshotsLanding}
                render={(routeProps) => (
                  <React.Suspense
                    fallback={<Loader type="circular" align="center" />}>
                    <DFSnapshotsLanding {...routeProps} />
                  </React.Suspense>
                )}
              />
              <Route
                exact
                path="/reports/df_snapshots"
                // component={DFSnapshotsLanding}
                render={(routeProps) => (
                  <React.Suspense
                    fallback={<Loader type="circular" align="center" />}>
                    <DFSnapshotsLanding {...routeProps} />
                  </React.Suspense>
                )}
              />
              <Route
                exact
                path="/reports/dtickets/stats"
                // component={OpenOrdersLanding}
                render={(routeProps) => (
                  <React.Suspense
                    fallback={<Loader type="circular" align="center" />}>
                    <OpenOrdersLanding {...routeProps} />
                  </React.Suspense>
                )}
              />
              <Route
                exact
                path="/reports/pa_expiring/:payer_types"
                component={ExpiringPAsReportLanding}
              />
              <Route
                exact
                path="/reports/pa_expiring/:payer_types/:start/:end"
                component={ExpiringPAsReportLanding}
              />
              <Route
                exact
                path="/reports/ccs_mcal_pa_expiring"
                component={ExpiringCCSMCalReportLanding}
              />
              <Route
                exact
                path="/reports/ccs_mcal_pa_expiring/:days/:days_end"
                component={ExpiringCCSMCalReportLanding}
              />
              <Route
                exact
                path="/reports/cmn_expiring"
                component={ExpiringCMNsReportLanding}
              />
              <Route
                exact
                path="/reports/cmn_expiring/:days"
                component={ExpiringCMNsReportLanding}
              />
              <Route
                exact
                path="/reports/cmn_expiring/:days/:days_end"
                component={ExpiringCMNsReportLanding}
              />
              <Route
                exact
                path="/reports/cmn_expiring_activity"
                component={ExpiringCMNsReportBySituation}
              />
              <Route
                exact
                path="/reports/careconnect"
                component={CareConnectReports}
              />
              <Route
                exact
                path="/reports/gso/serial"
                component={GSOSerialReport}
              />
              <Route
                exact
                path="/reports/recurring"
                component={RecurringReport}
              />

              <Route
                exact
                path="/reports/wos/pa_checks"
                component={WosPaChecks}
              />
              <Route
                exact
                path="/reports/open-orders-tracking/:start/:end"
                component={OpenOrdersTrackingReportLanding}
              />
              <Route exact path="/teams" component={TeamsLanding} />
              <Route exact path="/dcs" component={DCsLanding} />
              <Route exact path="/unbilled" component={UBLanding} />
              <Route exact path="/unbilled_stats" component={UBStatsLanding} />
              <Route
                exact
                path="/unbilled_stats/:payer_group"
                component={UBStatsLanding}
              />
              <Route
                exact
                path="/unbilled/account/:accountNumber"
                component={UBLanding}
              />
              <Route
                exact
                path="/unbilled/:payer_group/filters/:pa_state/:action_state/:page"
                component={UBLanding}
              />
              <Route
                exact
                path="/useradmin/users/"
                // component={UserAdmin}
                render={(routeProps) => (
                  <React.Suspense
                    fallback={<Loader type="circular" align="center" />}>
                    <UserAdmin {...routeProps} />
                  </React.Suspense>
                )}
              />
              <Route
                exact
                path="/useradmin/users/:by/:group_field"
                // component={UserAdmin}
                render={(routeProps) => (
                  <React.Suspense
                    fallback={<Loader type="circular" align="center" />}>
                    <UserAdmin {...routeProps} />
                  </React.Suspense>
                )}
              />
              <Route
                exact
                path="/useradmin/users/:by/:group_field/:field_value"
                // component={UserAdmin}
                render={(routeProps) => (
                  <React.Suspense
                    fallback={<Loader type="circular" align="center" />}>
                    <UserAdmin {...routeProps} />
                  </React.Suspense>
                )}
              />
              <Route exact path="/myteams" component={MyTeams} />
              <Route exact path="/myteams/:cat" component={MyTeams} />
              <Route exact path="/myteams/:cat/:team" component={MyTeams} />
              <Route
                exact
                path="/myteams/:cat/:team/:dfint"
                component={MyTeams}
              />
              {/* 
              <Route path="/pa_expiring" component={ExpiringPAsLanding} />
              */}
              <Route
                path="/pa_expiring/:payer_types"
                render={(routeProps) => <ExpiringPAsLanding {...routeProps} />}
              />

              <Route path="/pa_errors" component={PaErrorsLanding} />
              <Route
                exact
                path="/todos/:todoId"
                component={TodosLandingSingle}
              />
              <Route exact path="/todos_table" component={TodosLandingTable} />
              <Route exact path="/todos" component={TodosLandingNew} />
              <Route exact path="/todos_lite" component={TodosLandingSimple} />
              <Route
                exact
                path="/mobile/single_todo/:todoId"
                component={MobileTodosLandingSingle}
              />

              <Route
                exact
                path="/todos_summary"
                component={TodosSummaryLanding}
              />
              <Route
                exact
                path="/outbound_requests"
                component={DocRequestsLanding}
              />

              <Route
                exact
                path="/active_patients"
                component={ActivePatientsLanding}
              />

              <Route
                exact
                path="/outbound_requests/:page"
                component={DocRequestsLanding}
              />
              <Route
                exact
                path="/"
                render={() => <DfaLanding kind="workunits" />}
              />
              <Route
                exact
                path="/redirect_to_patient/:accountNumber/:tabName"
                render={() => <AccountToIdnLanding basePath="dfa" />}
              />

              <Route
                exact
                path="/redirect_to_mobile/:accountNumber/:tabName/:extras"
                render={() => <AccountToIdnLanding basePath="mobile" />}
              />

              <Route
                exact
                path="/dfa/account_docs/:accountNumber"
                component={AccountToIdnLanding}
              />
              <Route
                exact
                path="/dfa/doc_by_key/:docKey"
                component={DocKeyToIdnLanding}
              />
              <Route
                exact
                path="/dfa/gen/:idnStr/tabs/:tab"
                component={GeneralLanding}
              />
              <Route
                exact
                path="/dfa/idn/:idnStr"
                //render={() => <IdnLanding />}
                render={(props) => (
                  <React.Suspense
                    fallback={<Loader type="circular" align="center" />}>
                    <IdnLanding {...props} />
                  </React.Suspense>
                )}
              />
              <Route
                exact
                path="/dfa/idn/:idnStr/tabs/:tab"
                //render={() => <IdnLanding />}
                render={(props) => (
                  <React.Suspense
                    fallback={<Loader type="circular" align="center" />}>
                    <IdnLanding {...props} />
                  </React.Suspense>
                )}
              />
              <Route
                exact
                path="/dfa/idn/:idnStr/tabs/:tab/:tabParam"
                //render={() => <IdnLanding />}
                render={(props) => (
                  <React.Suspense
                    fallback={<Loader type="circular" align="center" />}>
                    <IdnLanding {...props} />
                  </React.Suspense>
                )}
              />
              <Route
                exact
                path="/dfa/idn/:idnStr/tabs/:tab/:posInSet/:docName"
                //render={() => <IdnLanding />}
                render={(props) => (
                  <React.Suspense
                    fallback={<Loader type="circular" align="center" />}>
                    <IdnLanding {...props} />
                  </React.Suspense>
                )}
              />

              {/* BEGIN no app bar dfa idn landings */}
              <Route
                exact
                path="/dfa_no_app_bar/idn/:idnStr"
                //render={() => <IdnLanding basePath="dfa_no_app_bar" />}
                render={(props) => (
                  <React.Suspense
                    fallback={<Loader type="circular" align="center" />}>
                    <IdnLanding {...props} basePath="dfa_no_app_bar" />
                  </React.Suspense>
                )}
              />
              <Route
                exact
                path="/dfa_no_app_bar/idn/:idnStr/tabs/:tab"
                //render={() => <IdnLanding basePath="dfa_no_app_bar" />}
                render={(props) => (
                  <React.Suspense
                    fallback={<Loader type="circular" align="center" />}>
                    <IdnLanding {...props} basePath="dfa_no_app_bar" />
                  </React.Suspense>
                )}
              />
              <Route
                exact
                path="/dfa_no_app_bar/idn/:idnStr/tabs/:tab/:tabParam"
                //render={() => <IdnLanding basePath="dfa_no_app_bar" />}
                render={(props) => (
                  <React.Suspense
                    fallback={<Loader type="circular" align="center" />}>
                    <IdnLanding {...props} basePath="dfa_no_app_bar" />
                  </React.Suspense>
                )}
              />
              <Route
                exact
                path="/dfa_no_app_bar/idn/:idnStr/tabs/:tab/:posInSet/:docName"
                //render={() => <IdnLanding basePath="dfa_no_app_bar" />}
                render={(props) => (
                  <React.Suspense
                    fallback={<Loader type="circular" align="center" />}>
                    <IdnLanding {...props} basePath="dfa_no_app_bar" />
                  </React.Suspense>
                )}
              />
              {/* END no app bar dfa idn landings */}

              {/* BEGIN mobile dfa idn landings */}
              <Route
                exact
                path="/mobile/idn/:idnStr"
                // render={() => <IdnMobileLanding basePath="mobile" />}
                render={(props) => (
                  <React.Suspense
                    fallback={<Loader type="circular" align="center" />}>
                    <IdnMobileLanding {...props} basePath="mobile" />
                  </React.Suspense>
                )}
              />
              <Route
                exact
                path="/mobile/idn/:idnStr/tabs/:tab"
                render={(props) => (
                  <React.Suspense
                    fallback={<Loader type="circular" align="center" />}>
                    <IdnMobileLanding {...props} basePath="mobile" />
                  </React.Suspense>
                )}
              />
              <Route
                exact
                path="/mobile/idn/:idnStr/tabs/:tab/:tabParam"
                render={(props) => (
                  <React.Suspense
                    fallback={<Loader type="circular" align="center" />}>
                    <IdnMobileLanding {...props} basePath="mobile" />
                  </React.Suspense>
                )}
              />

              <Route
                exact
                path="/mobile/idn/:idnStr/tabs/consent/:acctMember/:flow/:flow_record_id"
                render={(props) => (
                  <React.Suspense
                    fallback={<Loader type="circular" align="center" />}>
                    <IdnMobileLanding {...props} basePath="mobile" />
                  </React.Suspense>
                )}
              />

              <Route
                exact
                path="/mobile/idn/:idnStr/tabs/:tab/:posInSet/:docName"
                // render={() => <IdnMobileLanding basePath="mobile" />}
                render={(props) => (
                  <React.Suspense
                    fallback={<Loader type="circular" align="center" />}>
                    <IdnMobileLanding {...props} basePath="mobile" />
                  </React.Suspense>
                )}
              />
              {/* END mobile dfa idn landings */}

              <Route
                path="/dfa/workunits"
                render={() => <DfaLanding kind="workunits" />}
              />
              <Route
                path="/dfa/single_workunit"
                render={() => <DfaLanding kind="single_workunit" />}
              />
              <Route path="/home" component={Home} />
              <Route
                exact
                path="/mailboxes/:tabName"
                component={MailBoxesLanding}
              />
              <Route
                exact
                path="/mailboxes/:tabName/:subTabName"
                component={MailBoxesLanding}
              />
              <Route
                exact
                path="/salesdash/:page/:tabName"
                component={SalesDashLanding}
              />
              <Route
                exact
                path="/salesdash/:page/:region/:salesperson/:workBucket/:category/:year/:month/:day"
                component={SalesDashLanding}
              />
              {/* <Route exact path="/careconnect" component={CareConnectLanding} /> */}
              <Route
                exact
                path="/reports/dfworkunits/:tabName"
                component={DFWorkunitsReportsLanding}
              />
              <Route
                exact
                path="/reports/dfworkunits/by_date_by_hours/:teams"
                component={ReportByDateAndHours}
              />
              <Route
                exact
                path="/reports/onboarded"
                component={OnboardedReport}
              />
              <Route
                exact
                path="/mednec_issuelog"
                component={MednecIssueLanding}
              />
              <Route
                exact
                path="/confirmationlog"
                component={ConfirmationLogLanding}
              />
              <Route
                exact
                path="/rentals/active"
                component={ActiveRentalsLanding}
              />
              <Route
                exact
                path="/dtrack/wo"
                // component={DTrackLanding}
                render={(routeProps) => (
                  <React.Suspense
                    fallback={<Loader type="circular" align="center" />}>
                    <DTrackLanding {...routeProps} />
                  </React.Suspense>
                )}
              />
              <Route exact path="/dtrack/tools" component={DTTools} />
              <Route
                exact
                path="/dtrack/wo/:work_order_id"
                // component={WorkOrder}
                render={(routeProps) => (
                  <React.Suspense
                    fallback={<Loader type="circular" align="center" />}>
                    <WorkOrder {...routeProps} />
                  </React.Suspense>
                )}
              />

              <Route
                exact
                path="/patient_letters"
                component={PatientLettersReportLanding}
              />
              <Route
                exact
                path="/adjustments"
                component={AdjRequestsReportLanding}
              />
              <Route
                exact
                path="/open_counts/:email"
                component={OpenCountsLanding}
              />
              <Route exact path="/mappingtool" component={MappingToolLanding} />
              <Route
                exact
                path="/appeals"
                component={AppealRequestsReportLanding}
              />
              <Route
                exact
                path="/pp_registrations"
                component={PatientPortalRegistrationsLanding}
              />
              <Route exact path="/sn_lookup" component={SNLookupLanding} />
              <Route
                exact
                path="/reports/sn_lookup"
                component={SNLookupLanding}
              />
              <Route
                exact
                path="/workorders"
                component={WorkOrdersReportsLanding}
              />
              <Route exact path="/create_shell" component={IdnShellLanding} />
              <Route
                exact
                path="/reports/ops_forms"
                component={OpsFormsReportLanding}
              />
              <Route
                path="/reports/allscripts"
                component={AllScriptsReportRouter}
              />
              <Route
                exact
                path="/admin/comm_mgt/client_apps/:tabName"
                component={ClientAppsLanding}
              />
              <Route exact path="/sitemap" component={SiteMapLanding} />

              <Route exact path="/fb/search" component={FBPatientLanding} />
              <Route exact path="/fba/search" component={FBAccountingLanding} />
              <Route
                exact
                path="/fba/indexing"
                component={FBAccountingIndexingLanding}
              />
              <Route
                exact
                path="/pap_frequency_calculator"
                component={PAPCalculatorLanding}
              />
              <Route exact path="/catalog" component={CatalogMaintainance} />
              <Route exact path="/oxygen_counts" component={OxygenCounts} />
              <Route
                path="/admin/fa/:tabName"
                // component={FixedAssetAdminLanding}
                render={(routeProps) => (
                  <React.Suspense
                    fallback={<Loader type="circular" align="center" />}>
                    <FixedAssetAdminLanding {...routeProps} />
                  </React.Suspense>
                )}
              />

              <Route
                exact
                path="/pdf_packets/:packet_id/"
                component={PdfViewer}
              />
              <Route exact path="/dt" component={DeliveryTrack} />
              <Route
                exact
                path="/dt_summary"
                component={DeliveryTrackSummary}
              />
              <Route exact path="/summary_detail" component={SummaryDetail} />
              <Route exact path="/routing_summary" component={RoutingSummary} />

              <Route exact path="/gso-tracking" component={GSOTracking} />

              <Route
                exact
                path="/consent/mobile/:acctMember/:flow/:flow_record_id"
                component={ConsentDirectLanding}
              />

              <Route exact path="/announcements" component={Announcements} />
              <Route
                exact
                path="/announcements/registration"
                component={AnnouncementForm}
              />
            </main>
          </div>
        </MuiThemeProvider>
      </div>
    );
  }
}

const mapStateToProps = (stateFromStore) => ({
  me: stateFromStore.me,
  lookups: stateFromStore.lookups,
  room: stateFromStore.room,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      emit_df_activity_ac,
      redirectToDfa: (path) => {
        return push(path);
      },
    },
    dispatch
  );

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(App))
);

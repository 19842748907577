import {
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import moment from 'moment';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  confirmAccountConsent_ac,
  fetchAccountConsent_ac,
  updateHDMSField_ac,
} from '../../actions/account_in_view.ac';
import { open_snack_ac as openSnack } from '../../actions/snack.ac';

const styles = (theme) => ({
  card: {
    marginBottom: 20,
    border: '2px solid rgba(0,0,0,0.14)',
    borderRadius: 6,
  },
  commentForm: {
    width: '100%',
  },
  statusForm: {
    padding: '8px 10px',
  },
  tabsRoot: {
    minHeight: 24,
    maxHeight: 48,
  },
  root: {
    // width: 800,
  },
  tabRoot: {
    minWidth: 40,
    maxWidth: 400,
    '& .labelContainer': {
      padding: '6px 12px',
    },
    '&:first-child': {
      '& .labelContainer': {
        paddingLeft: 6,
      },
    },
    '&:last-child': {
      '& .labelContainer': {
        paddingRight: 6,
      },
    },
  },
  tabSpecificContainer: {
    overflowX: 'hidden',
    padding: '0px 8px',
  },
  tabOverflow: {
    height: '50vh',
    overflow: 'auto',
  },
  formContainer: {},
  listContainer: {},
  addButton: {
    marginTop: '6px',
    transition: 'all 0.5s ease-in-out',
  },
  checkbox: {
    padding: '6px 6px 6px 0px',
  },

  baseSwitch: {
    marginRight: 8,
    fontSize: 11,
    fontWeight: 'bold',
    color: 'rgb(156, 39, 176)',
    border: '1px solid rgb(156, 39, 176)',
    padding: '8px 12px',
    borderRadius: ' 6px',
    cursor: 'pointer',
  },
  switchOn: {
    backgroundColor: 'rgb(156, 39, 176)',
    color: 'white',
  },
  error: {
    color: 'red',
    fontSize: 12,
    margin: '8px 0px',
  },
  timelineContainer: {
    maxHeight: 'calc(100vh - 160px)',
    overflowY: 'auto',
    overflowX: 'hidden',
  },
});

export const isValidEmail = (email) => {
  if (!email.match(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/)) {
    return false;
  }
  return true;
};

const convertTenDigitPhoneToDashFormat = (phone) => {
  return phone
    .replace(/[^0-9]/g, '')
    .replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
};

const normalizeToPhoneNumberFormat = (phoneNumber) => {
  let normalized = phoneNumber.toString().replace(/[^0-9]/g, '');
  if (normalized.length === 11) {
    // console.log({ normalized, first: normalized[0] });
    return normalized[0] === '1'
      ? convertTenDigitPhoneToDashFormat(normalized.substr(1))
      : null;
  }
  // console.log({ normalized });
  return normalized.length === 10
    ? convertTenDigitPhoneToDashFormat(normalized)
    : null;
};

const isValidPhone = (phoneNumber) => {
  if (normalizeToPhoneNumberFormat(phoneNumber) === null) {
    return false;
  }
  return true;
};

const get_pending_transition_label = (current, proposed) => {
  /* handle variations of the different modes
      current: both on
        new: stay the same (no chagnes), but just want to resend the invitations
      current: both on
        new: one on, other off
      current: one on, other off
        new: both on
      current: one on, other off
        new: switch to other mode
    */

  //both SUBSCRIBED
  if (
    current.mode_sms.consent === true &&
    current.mode_email.consent === true
  ) {
    //remaining both SUBSCRIBED
    if (
      proposed.mode_sms.consent === true &&
      proposed.mode_email.consent === true
    ) {
      //check for changes in the HDMS fields
      return `Clicking will send new links to ${proposed['mode_sms'].value} and ${proposed['mode_email'].value} to verify.`;
    }

    // sms changing to NON CONSENT
    if (proposed.mode_sms.consent === false) {
      return 'Confirm will update SMS to NON CONSENT';
    }

    return 'Clicking will update EMAIL to NON CONSENT';
  }

  //currently, one SUBSCRIBED, one NON CONSENT
  let subscribed_mode = current.mode_sms.consent === true ? 'sms' : 'email';
  let non_subscribed_mode =
    current.mode_sms.consent === false ? 'sms' : 'email';

  if (proposed['mode_' + non_subscribed_mode].consent === true) {
    //this should reset the expiration date for BOTH modes
    if (proposed['mode_' + subscribed_mode].consent === false) {
      //this is swapping the consent state of the modes
      //(the one that was subscribed is now non consent, and the one that was non consent is now subscribed)
      return `Clicking will update
        ${subscribed_mode.toUpperCase()}
         to DECLINED CONSENT, update
        ${non_subscribed_mode.toUpperCase()} 
         to SUBSCRIBED and send a new link ${
           proposed['mode_' + non_subscribed_mode].value
         } to verify.`;
    }

    if (current.is_expired) {
      return `Clicking will update
        ${non_subscribed_mode.toUpperCase()}
        to SUBSCRIBED and send a new link to ${
          proposed['mode_' + non_subscribed_mode].value
        } to verify. Because the link sent for ${subscribed_mode.toUpperCase()} verification has expired, a new link will be sent to ${
        proposed['mode_' + subscribed_mode].value
      } also to verify.`;
    }

    return `Clicking will update
      ${non_subscribed_mode.toUpperCase()}
       to SUBSCRIBED and send a new link to ${
         proposed['mode_' + subscribed_mode].value
       } to verify. Expiration date of the link sent for ${subscribed_mode.toUpperCase()} verification will be extended.`;
  }

  //unchanged pending verification - but user may just want to resend
  return `Clicking will send a new link (with new expiration date) to ${
    current['mode_' + subscribed_mode].value
  } to verify.`;
};

const UNABLE_TO_ONBOARD_REASONS = [
  'Select a Reason',
  'Patient Not Available',
  'Patient Unable to Communicate',
  'Patient Refused to Answer',
];

const useConsentActionPanel = (props) => {
  const {
    onConfirm,
    disabled,
    isPristine,
    setConsentRecord,
    reference,
    consentRecord,
    consentRecordOrig,
  } = props;

  const { state: consent_state } = consentRecord;
  const { state: consent_state_orig } = consentRecordOrig;

  const state_change_direction = [
    reference.CONSENT_STATES[consent_state_orig].name,
    reference.CONSENT_STATES[consent_state].name,
  ].join(' to ');
  /*
  no_attempts to no_attempts (0 to 0)
  declined_all to declined_all (2 to 2) -> "REAFFIRM NON-CONSENT STATUS"
  */

  let unableToOnboardBtnLabel = 'CONFIRM UNSUCCESSFUL ATTEMPT STATUS';
  let unableHelpText = 'Clicking will update status to UNSUCCESSFUL ATTEMPT.';
  let confirmButtonLabel = 'CONFIRM PREFERENCES';
  let confirmHelpText = 'Clicking will confirm you changes.';
  let flavor_change_direction = '';
  switch (state_change_direction) {
    case 'no_attempts to no_attempts':
      break;
    case 'no_attempts to pending_verification':
    case 'attempted_unsuccessful to pending_verification':
    case 'declined_all to pending_verification':
      confirmButtonLabel = 'SEND LINK(S) TO VERIFY';
      confirmHelpText =
        'Clicking will confirm your changes and send link(s) to verify.';
      break;
    case 'attempted_unsuccessful to attempted_unsuccessful':
      unableToOnboardBtnLabel = 'RE-CONFIRM UNSUCCESSFUL ATTEMPT STATUS';
      unableHelpText = 'Clicking will re-confirm UNSUCCESSFUL ATTEMPT status.';
      break;
    case 'declined_all to declined_all':
      confirmButtonLabel = 'RE-CONFIRM DECLINED CONSENT STATUS';
      confirmHelpText = 'Clicking will re-confirm DECLINED CONSENT status.';
      break;
    case 'pending_verification to pending_verification':
      // confirmButtonLabel = 'RE-SEND INVITATION(S) TO VERIFY';
      confirmHelpText = get_pending_transition_label(
        consentRecordOrig,
        consentRecord
      );
      break;
    case 'pending_verification to declined_all':
      confirmHelpText =
        'Clicking will update EMAIL and SMS to DECLINED CONSENT status. Previously sent links will be invalidated.';
      break;
    case 'verified to pending_verification':
      confirmHelpText =
        'Clicking will update the status back to PENDING VERIFICATION';
      break;
    default:
      switch (reference.CONSENT_STATES[consent_state].name) {
        case 'declined_all':
          confirmHelpText =
            'Clicking will update EMAIL and SMS to DECLINED CONSENT status. ';
          break;
        case 'verified': //can only come from already verified
          //get the new proposed flavor
          flavor_change_direction = [
            reference.VERIFIED_FLAVORS[consentRecordOrig.verified_flavor_state]
              .name,
            reference.VERIFIED_FLAVORS[consentRecord.verified_flavor_state]
              .name,
          ].join(' to ');

          switch (flavor_change_direction) {
            case 'declined_other_mode to other_mode_pending': {
              //determine the mode that is switching from declined to pending
              let mode_that_is_switching =
                consentRecordOrig.mode_sms.verified === false ? 'sms' : 'email';

              confirmHelpText = `Clicking will update ${mode_that_is_switching.toUpperCase()} to SUBSCRIBED and send a new link to ${
                consentRecord['mode_' + mode_that_is_switching].value
              } to verify.`;
              break;
            }
            case 'other_mode_pending to other_mode_pending': {
              let mode_that_is_pending =
                consentRecord.mode_sms.verified === true ? 'email' : 'sms';
              confirmHelpText = `Clicking will send a new link to ${
                consentRecord['mode_' + mode_that_is_pending].value
              } to verify.`;
              break;
            }
            case 'other_mode_pending to declined_other_mode': {
              let mode_that_is_switching =
                consentRecordOrig.mode_sms.verified === true ? 'sms' : 'email';
              confirmHelpText = `Clicking will update ${mode_that_is_switching.toUpperCase()} to DECLINED CONSENT.`;
              break;
            }
            case 'verified_both to declined_other_mode': {
              let mode_that_is_switching =
                consentRecord.mode_sms.consent === true ? 'email' : 'sms';
              confirmHelpText = `Clicking will update ${mode_that_is_switching.toUpperCase()} to DECLINED CONSENT.`;
              break;
            }
            case 'verified_both to verified_both': {
              confirmHelpText = `Already verified.`;
              break;
            }
            default: {
              // confirmHelpText = flavor_change_direction;
              confirmHelpText = '';
              break;
            }
          }
          break;
        default:
          break;
      }
      break;
  }

  const [selectedReason, setSelectedReason] = useState(0);
  const handleReasonChange = (e) => {
    setSelectedReason(e.target.value);
  };

  const handleUnableToOnboard = () => {
    setConsentRecord({
      ...consentRecord,
      state:
        reference.CONSENT_STATES_BY_STATE_NAME['attempted_unsuccessful'].state,
    });

    onConfirm(
      {
        state:
          reference.CONSENT_STATES_BY_STATE_NAME['attempted_unsuccessful']
            .state,
      },
      {
        reason: UNABLE_TO_ONBOARD_REASONS[selectedReason],
      },
      () => {
        setSelectedReason(0);
      }
    );
  };

  const handleOnConfirm = () => {
    onConfirm({}, {});
  };

  return {
    selectedReason,
    handleReasonChange,
    handleUnableToOnboard,
    handleOnConfirm,
    unableToOnboardBtnLabel,
    unableHelpText,
    confirmButtonLabel,
    confirmHelpText,
    disabled,
    isPristine,
    flavor_change_direction,
  };
};

const ConsentActionPanel = (props) => {
  const { onReset, disabled, consentRecord, reference, isPristine } = props;

  const {
    selectedReason,
    handleReasonChange,
    handleUnableToOnboard,
    handleOnConfirm,
    unableToOnboardBtnLabel,
    unableHelpText,
    confirmButtonLabel,
    confirmHelpText,
    flavor_change_direction,
  } = useConsentActionPanel(props);

  const { state: consent_state } = consentRecord;

  return (
    <Grid container spacing={8}>
      <Grid item xs={6} sm={4} style={{ padding: 16 }}>
        <Button
          disabled={disabled}
          variant="outlined"
          color="default"
          onClick={onReset}>
          Refresh
        </Button>
      </Grid>
      {[
        reference.CONSENT_STATES_BY_STATE_NAME['no_attempts'].state,
        reference.CONSENT_STATES_BY_STATE_NAME['attempted_unsuccessful'].state,
      ].includes(consent_state) ? (
        <>
          <Grid item xs={6} sm={4} style={{ padding: 16 }}>
            <Select
              value={selectedReason}
              style={{ width: 200, fontSize: 12 }}
              disabled={disabled || !isPristine}
              onChange={handleReasonChange}>
              {UNABLE_TO_ONBOARD_REASONS.map((reason, reason_index) => (
                <MenuItem key={reason_index} value={reason_index}>
                  {reason}
                </MenuItem>
              ))}
            </Select>
            <br />

            <label style={{ fontSize: 12 }}>
              If you are unable to obtain a decision (regarding consent of
              communication) from the patient, select a reason why and click{' '}
              {unableToOnboardBtnLabel}.
            </label>
          </Grid>
          <Grid item xs={6} sm={4} style={{ padding: 16 }}>
            <Button
              variant="contained"
              color="primary"
              disabled={disabled || !isPristine || selectedReason === 0}
              onClick={() => {
                handleUnableToOnboard();
                return true;
              }}>
              {unableToOnboardBtnLabel}
            </Button>
            <br />
            {!(disabled || !isPristine || selectedReason === 0) && (
              <label style={{ fontSize: 12 }}>{unableHelpText}</label>
            )}
          </Grid>
        </>
      ) : (
        <>
          <Grid item xs={6} sm={4} style={{ padding: 16 }} />
          <Grid item xs={6} sm={4} style={{ padding: 16 }}>
            <Button
              disabled={
                disabled ||
                consentRecord.mode_email.consent === null ||
                consentRecord.mode_sms.consent === null ||
                flavor_change_direction === 'verified_both to verified_both'
              }
              variant="outlined"
              color="primary"
              onClick={handleOnConfirm}>
              {confirmButtonLabel}
            </Button>
            <br />
            <label style={{ fontSize: 12 }}>{confirmHelpText}</label>
          </Grid>
        </>
      )}
    </Grid>
  );
};

const ConsentActionPanelMobile = (props) => {
  const { onReset, disabled, consentRecord, reference, isPristine } = props;

  const {
    selectedReason,
    handleReasonChange,
    handleUnableToOnboard,
    handleOnConfirm,
    unableToOnboardBtnLabel,
    unableHelpText,
    confirmButtonLabel,
    confirmHelpText,
    flavor_change_direction,
  } = useConsentActionPanel(props);

  const { state: consent_state } = consentRecord;

  return (
    <Grid container spacing={8}>
      <Grid item xs={12} sm={4} style={{ padding: 16 }}>
        <Button
          disabled={disabled}
          variant="outlined"
          color="default"
          onClick={onReset}>
          Refresh
        </Button>
      </Grid>
      {[
        reference.CONSENT_STATES_BY_STATE_NAME['no_attempts'].state,
        reference.CONSENT_STATES_BY_STATE_NAME['attempted_unsuccessful'].state,
      ].includes(consent_state) ? (
        <>
          <Grid item xs={12} sm={4} style={{ padding: 16 }}>
            <Select
              value={selectedReason}
              style={{ width: 200, fontSize: 12 }}
              disabled={disabled || !isPristine}
              onChange={handleReasonChange}>
              {UNABLE_TO_ONBOARD_REASONS.map((reason, reason_index) => (
                <MenuItem key={reason_index} value={reason_index}>
                  {reason}
                </MenuItem>
              ))}
            </Select>
            <br />

            <label style={{ fontSize: 12 }}>
              If you are unable to obtain a decision (regarding consent of
              communication) from the patient, select a reason why and click{' '}
              {unableToOnboardBtnLabel}.
            </label>
          </Grid>
          <Grid item xs={12} sm={4} style={{ padding: 16 }}>
            <Button
              variant="contained"
              color="primary"
              disabled={disabled || !isPristine || selectedReason === 0}
              onClick={() => {
                handleUnableToOnboard();
                return true;
              }}>
              {unableToOnboardBtnLabel}
            </Button>
            <br />
            {!(disabled || !isPristine || selectedReason === 0) && (
              <label style={{ fontSize: 12 }}>{unableHelpText}</label>
            )}
          </Grid>
        </>
      ) : (
        <>
          <Grid item xs={12} sm={4} style={{ padding: 16 }} />
          <Grid item xs={12} sm={4} style={{ padding: 16 }}>
            <Button
              disabled={
                disabled ||
                consentRecord.mode_email.consent === null ||
                consentRecord.mode_sms.consent === null ||
                flavor_change_direction === 'verified_both to verified_both'
              }
              variant="outlined"
              color="primary"
              onClick={handleOnConfirm}>
              {confirmButtonLabel}
            </Button>
            <br />
            <label style={{ fontSize: 12 }}>{confirmHelpText}</label>
          </Grid>
        </>
      )}
    </Grid>
  );
};

const useCommMode = (props) => {
  const { mode, validator, disableActionPanel } = props;

  const [isHdmsEditable, setIsHdmsEditable] = useState(false);
  const [modeValueIsValid, setModeValueIsValid] = useState(
    mode.value ? validator(mode.value) : false
  );
  const [newHdmsFieldValue, setNewHdmsFieldValue] = useState('');
  const [newHdmsFieldValueValid, setNewHdmsFieldValueValid] = useState(
    mode.value
  );

  const editHdmsMode = (on) => {
    if (on) {
      setNewHdmsFieldValue(mode.value);
      setModeValueIsValid(newHdmsFieldValue && validator(newHdmsFieldValue));
      setIsHdmsEditable(true);
      disableActionPanel(true);
    } else {
      // setModeValueIsValid(mode.value ? validator(mode.value) : false);
      setModeValueIsValid(
        newHdmsFieldValue ? validator(newHdmsFieldValue) : false
      );

      setNewHdmsFieldValue('');
      setIsHdmsEditable(false);
      disableActionPanel(false);
    }
  };

  return {
    editHdmsMode,
    isHdmsEditable,
    modeValueIsValid,
    newHdmsFieldValue,
    newHdmsFieldValueValid,
    setNewHdmsFieldValue,
    setNewHdmsFieldValueValid,
    setModeValueIsValid,
  };
};

const CommMode = (props) => {
  const {
    mode,
    mode_label,
    which_mode,
    onChange,
    validator,
    updateFieldValue,
    isPristine,
    current_consent_state,
    current_mode,
    is_expired,
    expires,
    reference,
    openSnack,
    mobile = false,
  } = props;

  const {
    modeValueIsValid,
    newHdmsFieldValue,
    newHdmsFieldValueValid,
    setNewHdmsFieldValue,
    setNewHdmsFieldValueValid,
    isHdmsEditable,
    editHdmsMode,
  } = useCommMode(props);

  return (
    <Grid
      container
      spacing={8}
      style={{
        padding: 4,
        [mobile ? 'borderBottom' : 'border']: '2px solid #000',
        marginBottom: 32,
      }}>
      <Grid item sm={6} xs={12}>
        <FormGroup
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
          }}>
          <TextField
            label={'HDMS ' + mode_label}
            value={
              isHdmsEditable
                ? newHdmsFieldValue
                : mode.value === null
                ? ''
                : mode.value
            }
            InputLabelProps={{ shrink: true }}
            style={{ marginBottom: 12 }}
            onChange={(e) => {
              setNewHdmsFieldValue(e.target.value);
              setNewHdmsFieldValueValid(
                validator(e.target.value) || e.target.value === ''
              );
            }}
            onClick={() => {
              if (!isHdmsEditable) {
                // if (
                //   current_consent_state ===
                //     reference.CONSENT_STATES_BY_STATE_NAME[
                //       'pending_verification'
                //     ].state &&
                //   current_mode.consent === true
                // ) {
                //   //Do not allow changes to HDMS fields if the state is pending_verification and the mode is consented
                //   //user must move state to DOES NOT CONSENT first
                //   openSnack({
                //     message:
                //       'Warning (Code A) - You must first change the consent status to "Does Not Consent" before you can edit the HDMS fields. This is to prevent inadvertent changes to the HDMS fields while a verification link is pending.',
                //     variant: 'warning',
                //   });

                //   return;
                // }

                // if (mode.consent === true ){
                if (mode.consent === true || current_mode.consent === true) {
                  openSnack({
                    message:
                      'Warning (Code B) - You must first change the consent status to "Does Not Consent" before you can edit the HDMS fields. This is to prevent inadvertent changes to the HDMS fields while a verification link is pending.',
                    variant: 'warning',
                  });

                  return;
                }

                editHdmsMode(true);
              }
            }}
            InputProps={{
              readOnly: !isHdmsEditable,
              style: {
                width: '100%',
                cursor: 'pointer',
                backgroundColor: isHdmsEditable ? 'yellow' : 'white',
              },
            }}
          />
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
            }}>
            {isHdmsEditable && (
              <>
                <Button
                  size="small"
                  style={{ marginLeft: 8, marginRight: 8 }}
                  onClick={() => {
                    editHdmsMode(false);
                  }}
                  variant="contained">
                  Cancel
                </Button>
                <Button
                  style={{ marginLeft: 8, marginRight: 8 }}
                  size="small"
                  color="primary"
                  onClick={() => {
                    updateFieldValue(which_mode, newHdmsFieldValue, () => {
                      editHdmsMode(false);
                    });
                    return;
                  }}
                  disabled={!newHdmsFieldValueValid}
                  variant="contained">
                  Save To HDMS
                </Button>
              </>
            )}
          </div>
        </FormGroup>
      </Grid>
      <Grid item sm={6} xs={12}>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                disabled={isHdmsEditable || modeValueIsValid === false}
                checked={mode.consent === true && !isPristine}
                onChange={() => {
                  if (validator(mode.value) === false) {
                    return;
                  }
                  onChange(which_mode, true);
                }}
                value="subscribed"
                color="primary"
              />
            }
            label={
              reference &&
              [
                reference.CONSENT_STATES_BY_STATE_NAME['pending_verification']
                  .state,
                reference.CONSENT_STATES_BY_STATE_NAME['verified'].state,
              ].includes(current_consent_state) &&
              current_mode.consent === true
                ? current_mode.verified
                  ? `Subscribed (Verified)`
                  : is_expired
                  ? `Subscribed (Non-verified, Link Expired ${moment(
                      expires
                    ).format('MM/DD/YYYY hh:mm A')})`
                  : `Subscribed (Pending Verification, Link Expires ${moment(
                      expires
                    ).format('MM/DD/YYYY hh:mm A')})`
                : 'Subscribed'
            }
          />
          <FormControlLabel
            control={
              <Checkbox
                disabled={isHdmsEditable}
                checked={mode.consent === false && !isPristine}
                onChange={() => {
                  onChange(which_mode, false);
                }}
                value="does_not_consent"
                color="primary"
              />
            }
            label="Does Not Consent"
          />
        </FormGroup>
      </Grid>
    </Grid>
  );
};

const useConsentSettings = (props) => {
  const {
    consentRecord,
    consentRecordOrig,
    updateFieldValue,
    reference,
    setIsPristine,
    setConsentRecord,
  } = props;
  //make a copy of consentRecord and store it as a state
  const { state: current_consent_state } = consentRecordOrig;
  // const { state: consent_state } = consentRecord;

  const onUpdateFieldvalue = (which_mode, new_value, postAction) => {
    if (which_mode === 'mode_sms') {
      console.log({ new_value });
      let tmp_val = normalizeToPhoneNumberFormat(new_value);
      console.log({ tmp_val });
      new_value = tmp_val === null ? '' : tmp_val;
    } else {
      new_value = new_value.trim().toLowerCase();
    }
    console.log({ final: new_value });
    updateFieldValue(which_mode, new_value, () => {
      setConsentRecord({
        ...consentRecord,
        [which_mode]: {
          ...consentRecord[which_mode],
          value: new_value,
        },
      });
      postAction();
    });
  };

  const handleConsentChange = (mode, consent) => {
    setIsPristine(false);
    //here were figure out the state change direction, and set the state accordingly
    /* 
		first check for the INPROGRESS UI ONLY situation of one mode selected, other mode not selected
		
		there are 4 possible states described by reference.CONSENT_STATES_BY_STATE_NAME 
		*/
    let other_mode = mode === 'mode_email' ? 'mode_sms' : 'mode_email';
    let new_state = consentRecord.state;

    if (consentRecord[other_mode].consent === null) {
      //it's in an inprogress state, don't modify the state code
      setConsentRecord({
        ...consentRecord,
        state: new_state,
        [mode]: {
          ...consentRecord[mode],
          consent: consent,
        },
      });
      return;
    }
    //it's not in an inprogress state, so we need to set the state code to the appropriate value

    //handle verified state
    if (
      consentRecordOrig.state ===
      reference.CONSENT_STATES_BY_STATE_NAME['verified'].state
    ) {
      /* 
        There are the possible state transitions:
        FROM verified to:
        state 3. declined_all 
        state 2. pending_verification 
          (this is only possible if the current flavor is pending_other_mode AND user is changing the already verified mode to non-consent)
        
        remains state 4, flavor onliy change
      */

      if (consent === false) {
        if (consentRecord[other_mode].consent === false) {
          //the other mode is already in NON-consent state, so this is heading to declined_all
          new_state =
            reference.CONSENT_STATES_BY_STATE_NAME['declined_all'].state;

          setConsentRecord({
            ...consentRecord,
            state: new_state,
            [mode]: {
              ...consentRecord[mode],
              consent: consent,
              verified: false,
            },
          });
          return;
        }

        if (
          consentRecord[mode].verified === true &&
          consentRecord[other_mode].verified === false
        ) {
          new_state =
            reference.CONSENT_STATES_BY_STATE_NAME['pending_verification']
              .state;

          setConsentRecord({
            ...consentRecord,
            state: new_state,
            [mode]: {
              ...consentRecord[mode],
              consent: consent,
              verified: false,
            },
          });
          return;
        }
      }

      //flavor change, remain verified
      new_state = reference.CONSENT_STATES_BY_STATE_NAME['verified'].state;

      //determine new verified flavor state, this is only for figuring out the correct help text in the action panel
      let new_verified_flavor_state = Number(
        consentRecordOrig.verified_flavor_state
      );
      let verified_value = consentRecordOrig[mode].verified;
      switch (consentRecordOrig.verified_flavor_state) {
        case reference.VF_BY_NAME['declined_other_mode'].state:
          if (consentRecordOrig[mode].verified === false && consent === true) {
            new_verified_flavor_state =
              reference.VF_BY_NAME['other_mode_pending'].state;
            verified_value = false; //reset the verified value
          }
          break;
        case reference.VF_BY_NAME['other_mode_pending'].state:
        case reference.VF_BY_NAME['other_mode_expired'].state:
          if (consent === false) {
            if (consentRecordOrig[mode].verified === true) {
              //should not occur, would have been caught above
              return;
            }

            if (consentRecordOrig[mode].verified === false) {
              new_verified_flavor_state =
                reference.VF_BY_NAME['declined_other_mode'].state;
            }
          }
          verified_value = false; //reset the verified value
          break;
        case reference.VF_BY_NAME['verified_both'].state:
          if (consent === false) {
            new_verified_flavor_state =
              reference.VF_BY_NAME['declined_other_mode'].state;
            verified_value = false; //reset the verified value
          } else {
            //handle for when user temporarily sets both to DECLINED, then switches one back to SUBSCRIBED
            if (consentRecord[other_mode].consent === false) {
              new_verified_flavor_state =
                reference.VF_BY_NAME['declined_other_mode'].state;
              verified_value = false; //reset the verified value
            }
          }

          break;
        default:
          break;
      }

      setConsentRecord({
        ...consentRecord,
        state: new_state,
        [mode]: {
          ...consentRecord[mode],
          consent: consent,
          verified: verified_value,
        },
        verified_flavor_state: new_verified_flavor_state,
      });
      return;
    }

    if (consent === true || consentRecord[other_mode].consent === true) {
      new_state =
        reference.CONSENT_STATES_BY_STATE_NAME['pending_verification'].state;
    } else if (
      consent === false &&
      consentRecord[other_mode].consent === false
    ) {
      new_state = reference.CONSENT_STATES_BY_STATE_NAME['declined_all'].state;
    }

    setConsentRecord({
      ...consentRecord,
      state: new_state,
      [mode]: {
        ...consentRecord[mode],
        consent: consent,
      },
    });
  };

  const inQAMode = false;
  const { sms_stop = null } = consentRecordOrig;

  return {
    handleConsentChange,
    onUpdateFieldvalue,
    inQAMode,
    sms_stop,
    current_consent_state,
    reference,
  };
};

const ConsentSettings = (props) => {
  const {
    handleConsentChange,
    onUpdateFieldvalue,
    inQAMode,
    sms_stop,
    current_consent_state,
    reference,
  } = useConsentSettings(props);

  const { consentRecord, consentRecordOrig, disableActionPanel, isPristine } =
    props;

  const { state: consent_state } = consentRecord;

  return (
    <div>
      <Grid container spacing={8} direction="column">
        <Grid item>
          <div style={{ fontSize: 12 }}>
            Current Status of Consent: [{current_consent_state}]{' '}
            <strong style={{ fontSize: 14 }}>
              {reference.CONSENT_STATES[current_consent_state].header_text}
            </strong>{' '}
            as of{' '}
            {moment(consentRecordOrig.last_updated).format(
              'MM/DD/YYYY hh:mm A'
            )}
            <br />
            {reference.CONSENT_STATES[current_consent_state].detail_text}
            <br />
            {reference.CONSENT_STATES[current_consent_state].next_steps}
          </div>
          {sms_stop &&
            consentRecordOrig.mode_sms.value === sms_stop.number_stopped && (
              <div style={{ fontSize: 12, marginTop: 8, marginBottom: 8 }}>
                <strong style={{ color: 'red' }}>
                  ATTENTION: Patient texted 'STOP' from{' '}
                  {sms_stop.number_stopped} on{' '}
                  {moment(sms_stop.date_stopped).format('MM/DD/YYYY hh:mm A')}{' '}
                  to our Supercare SMS sending number {sms_stop.twilio_number}.
                  Patient will not receive any SMS messages from us until they
                  text 'START' to the same number.{' '}
                </strong>
              </div>
            )}

          {inQAMode && (
            <div>
              {consent_state !== current_consent_state && (
                <div style={{ fontSize: 12 }}>
                  Will change to: [{consent_state}]{' '}
                  {reference.CONSENT_STATES[consent_state].name}
                </div>
              )}
              {consentRecord.verified_flavor_state !==
                consentRecordOrig.verified_flavor_state && (
                <div style={{ fontSize: 12 }}>
                  Verified Flavor Will change to: [{consent_state}]{' '}
                  {reference.VERIFIED_FLAVORS[
                    consentRecord.verified_flavor_state
                  ] &&
                    reference.VERIFIED_FLAVORS[
                      consentRecord.verified_flavor_state
                    ].name}
                </div>
              )}
            </div>
          )}
        </Grid>
      </Grid>
      <CommMode
        openSnack={props.openSnack}
        disableActionPanel={disableActionPanel}
        updateFieldValue={onUpdateFieldvalue}
        isPristine={isPristine}
        onChange={handleConsentChange}
        validator={isValidPhone}
        consent_state={consentRecord.state}
        mode={consentRecord.mode_sms}
        current_mode={consentRecordOrig.mode_sms}
        other_mode={consentRecord.mode_email}
        mode_label="SMS (Alt Phone)"
        which_mode="mode_sms"
        reference={reference}
        current_consent_state={consentRecordOrig.state}
        is_expired={consentRecordOrig.is_expired}
        expires={consentRecordOrig.invite_expires}
      />
      <CommMode
        openSnack={props.openSnack}
        disableActionPanel={disableActionPanel}
        updateFieldValue={onUpdateFieldvalue}
        isPristine={isPristine}
        onChange={handleConsentChange}
        validator={isValidEmail}
        consent_state={consentRecord.state}
        mode={consentRecord.mode_email}
        current_mode={consentRecordOrig.mode_email}
        other_mode={consentRecord.mode_sms}
        mode_label="Email"
        which_mode="mode_email"
        reference={reference}
        current_consent_state={consentRecordOrig.state}
        is_expired={consentRecordOrig.is_expired}
        expires={consentRecordOrig.invite_expires}
      />
    </div>
  );
};

const ConsentSettingsMobile = (props) => {
  const {
    handleConsentChange,
    onUpdateFieldvalue,
    inQAMode,
    sms_stop,
    current_consent_state,
    reference,
  } = useConsentSettings(props);

  const { consentRecord, consentRecordOrig, disableActionPanel, isPristine } =
    props;

  const { state: consent_state } = consentRecord;

  return (
    <div>
      <div style={{ fontSize: 12, marginBottom: 12 }}>
        Current Status of Consent for Account: {consentRecordOrig._id}: <br />
        <strong style={{ fontSize: 14 }}>
          {reference.CONSENT_STATES[current_consent_state].header_text}
        </strong>
        <br />
        as of{' '}
        {moment(consentRecordOrig.last_updated).format('MM/DD/YYYY hh:mm A')}
        {/* <br />
            {reference.CONSENT_STATES[current_consent_state].detail_text}
            <br />
            {reference.CONSENT_STATES[current_consent_state].next_steps} */}
      </div>
      {sms_stop &&
        consentRecordOrig.mode_sms.value === sms_stop.number_stopped && (
          <div
            style={{
              fontSize: 12,
              border: '1px solid #000',
              borderRadius: '3px',
              color: 'red',
              fontWeight: 'bold',
              padding: 4,
              marginBottom: 8,
            }}>
            ATTENTION: Patient texted 'STOP' from {sms_stop.number_stopped} on{' '}
            {moment(sms_stop.date_stopped).format('MM/DD/YYYY hh:mm A')} to our
            Supercare SMS sending number {sms_stop.twilio_number}. Patient will
            not receive any SMS messages from us until they text 'START' to the
            same number.{' '}
          </div>
        )}

      {inQAMode && (
        <div>
          {consent_state !== current_consent_state && (
            <div style={{ fontSize: 12 }}>
              Will change to: [{consent_state}]{' '}
              {reference.CONSENT_STATES[consent_state].name}
            </div>
          )}
          {consentRecord.verified_flavor_state !==
            consentRecordOrig.verified_flavor_state && (
            <div style={{ fontSize: 12 }}>
              Verified Flavor Will change to: [{consent_state}]{' '}
              {reference.VERIFIED_FLAVORS[
                consentRecord.verified_flavor_state
              ] &&
                reference.VERIFIED_FLAVORS[consentRecord.verified_flavor_state]
                  .name}
            </div>
          )}
        </div>
      )}
      <CommMode
        mobile
        openSnack={props.openSnack}
        disableActionPanel={disableActionPanel}
        updateFieldValue={onUpdateFieldvalue}
        isPristine={isPristine}
        onChange={handleConsentChange}
        validator={isValidPhone}
        consent_state={consentRecord.state}
        mode={consentRecord.mode_sms}
        current_mode={consentRecordOrig.mode_sms}
        other_mode={consentRecord.mode_email}
        mode_label="SMS (Alt Phone)"
        which_mode="mode_sms"
        reference={reference}
        current_consent_state={consentRecordOrig.state}
        is_expired={consentRecordOrig.is_expired}
        expires={consentRecordOrig.invite_expires}
      />
      <CommMode
        mobile
        openSnack={props.openSnack}
        disableActionPanel={disableActionPanel}
        updateFieldValue={onUpdateFieldvalue}
        isPristine={isPristine}
        onChange={handleConsentChange}
        validator={isValidEmail}
        consent_state={consentRecord.state}
        mode={consentRecord.mode_email}
        current_mode={consentRecordOrig.mode_email}
        other_mode={consentRecord.mode_sms}
        mode_label="Email"
        which_mode="mode_email"
        reference={reference}
        current_consent_state={consentRecordOrig.state}
        is_expired={consentRecordOrig.is_expired}
        expires={consentRecordOrig.invite_expires}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  me: state.me,
  accountConsent: state.accountConsent,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      openSnack,
      confirmAccountConsent_ac,
      fetchAccountConsent_ac,
      updateHDMSField_ac,
    },
    dispatch
  );

const ConnectedConsentSettings = connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(ConsentSettings));

const ConnectedConsentActionPanel = connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(ConsentActionPanel));

const ConnectedConsentActionPanelMobile = connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(ConsentActionPanelMobile));

const ConnectedConsentSettingsMobile = connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(ConsentSettingsMobile));

export {
  ConnectedConsentActionPanel as ConsentActionPanel,
  ConnectedConsentActionPanelMobile as ConsentActionPanelMobile,
  ConnectedConsentSettings as ConsentSettings,
  ConnectedConsentSettingsMobile as ConsentSettingsMobile,
};

import React from 'react';
import { List, ListItem, ListItemText, Typography } from '@material-ui/core';

const OrderList = ({ items }) => {
  return (
    <List component="ol">
      {items.map((item, index) => (
        <ListItem key={index} component="li" style={{ padding: 0 }}>
          <ListItemText
            primary={
              <>
                <Typography inline variant="subtitle2" style={{ fontSize: 10 }}>
                  {`${index + 1}. ${item.product_desc} ${item.qty_ordered} `}
                </Typography>

                <Typography
                  inline
                  variant="subtitle2"
                  style={{ fontWeight: 'bold', fontSize: 10 }}>
                  {`${item.uom} ${item.wo_type_str}`}
                </Typography>
              </>
            }
          />
        </ListItem>
      ))}
    </List>
  );
};

export default OrderList;

import { Grid, Paper, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import React, { useRef, useState } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { IoMdSwap } from 'react-icons/io';
import { PiSignInBold, PiSignOutBold } from 'react-icons/pi';

import LineTypeChip from '../components/LineTypeChip';
import OrderList from '../components/OrderList';

const SummaryCard = ({
  id,
  data: wo,
  groupIndex,
  index,
  moveCard,
  recentlyMoved,
  classes,
}) => {
  const ref = useRef(null);
  const [showLineItems, setShowLineItems] = useState({});

  const [, drag] = useDrag({
    item: {
      type: 'card',
      item: { id, index, groupIndex },
    },
  });

  const [{ isOver }, drop] = useDrop({
    accept: 'card',
    drop(item) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.item.index;
      const dragGroupIndex = item.item.groupIndex;
      const hoverIndex = index;
      const hoverGroupIndex = groupIndex;

      // Prevent unnecessary reordering
      if (dragIndex === hoverIndex && dragGroupIndex === hoverGroupIndex)
        return;

      // Handle cross-group drag
      moveCard(dragGroupIndex, dragIndex, hoverGroupIndex, hoverIndex);

      // Update dragged item's indices
      item.index = hoverIndex;
      item.groupIndex = hoverGroupIndex;
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
  });

  const showLines = (rowId) => {
    setShowLineItems((prev) => ({
      ...prev,
      [rowId]: !prev[rowId],
    }));
  };

  drag(drop(ref));

  const renderAttribute = (text, color) => (
    <Typography style={{ color, fontWeight: 'bold', fontSize: 10 }}>
      {text}
    </Typography>
  );

  const RenderAttributes = (r) => {
    return (
      <>
        {(r.has_pickup_clone || r.allow_pickup_clone) &&
          renderAttribute('SLEEPTEST', 'purple')}
        {r.is_pickup_clone && renderAttribute('SLEEPTEST P/U', 'purple')}
        {r.milkrun && renderAttribute(`MRUN: ${r.milkrun}`, 'darkgreen')}
        {r.heavy && renderAttribute('HEAVYITEM', '#9C642F')}
      </>
    );
  };

  const renderPickerText = (pickerTxt) => {
    if (pickerTxt.substr(0, 4) === 'PEND') {
      return <strong style={{ color: 'red' }}>{pickerTxt}</strong>;
    } else if (pickerTxt.substr(-3) === 'BAD') {
      return (
        <strong style={{ color: 'red', textDecoration: 'underline' }}>
          {pickerTxt}
        </strong>
      );
    } else {
      return <strong style={{ color: '#000' }}>{pickerTxt}</strong>;
    }
  };

  const AlertMessages = ({ wo }) => {
    return (
      <>
        {wo.late_arrival && (
          <Paper className={`${classes.alertContainer} ${classes.blink}`}>
            <Typography className={classes.alertMessage}>
              <span className={classes.highlight}>Risk of Late Arrival</span>
            </Typography>
          </Paper>
        )}

        {wo.aging_dispatch && (
          <Paper className={classes.alertContainer}>
            <Typography className={classes.alertMessage}>
              Driver has{' '}
              <span className={classes.highlight}>NOT ACKNOWLEDGED</span>{' '}
              {wo.aging_dispatch} min after{' '}
              <span className={classes.highlight}>DISPATCH</span>
            </Typography>
          </Paper>
        )}
      </>
    );
  };

  return (
    <div
      key={index}
      ref={ref}
      style={{
        width: '100%',
        margin: '5px',
        opacity: isOver ? 0 : 1,
        border: isOver ? '2px dashed #428bca' : 'none',
        transition: 'background-color 0.2s ease, border 0.2s ease',
      }}>
      <Paper
        style={{
          padding: '0 5px',
          backgroundColor: recentlyMoved ? 'rgb(186 186 255)' : '#f3f3f4',
        }}>
        <Grid container justify="space-between">
          <Grid item>
            <Typography inline style={{ fontSize: 11, color: '#676a6c' }}>
              {wo.sched_time || wo.req_time} {wo.city_txt}
            </Typography>
            <Typography
              inline
              style={{
                fontSize: 11,
                paddingLeft: 5,
                color: '#428bca',
                fontWeight: 'bold',
              }}>
              {wo._id}
            </Typography>
            <Typography
              style={{
                fontSize: 10,
                color: 'black',
                fontWeight: 'bold',
              }}>
              {renderPickerText(wo.picker_txt_dnm)}
            </Typography>
          </Grid>
          <Grid item>
            <Grid container direction="column" spacing={8}>
              <Grid item>
                {wo.wo_types?.Exchange && (
                  <LineTypeChip
                    icon={IoMdSwap}
                    bgColor="#F8AC59"
                    text={wo.wo_types?.Exchange}
                    onClick={() => showLines(wo._id)}
                  />
                )}

                {wo.wo_types?.Pickup && (
                  <LineTypeChip
                    icon={PiSignOutBold}
                    bgColor="#24c6c8"
                    text={wo.wo_types?.Pickup}
                    onClick={() => showLines(wo._id)}
                  />
                )}

                {wo.wo_types?.Delivery && (
                  <LineTypeChip
                    icon={PiSignInBold}
                    bgColor="#1d84c6"
                    text={wo.wo_types?.Delivery}
                    onClick={() => showLines(wo._id)}
                  />
                )}
              </Grid>
              <Grid item style={{ alignSelf: 'end' }}>
                {RenderAttributes(wo)}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid style={{ paddingBottom: 5 }}>
          <AlertMessages wo={wo} />
        </Grid>

        {showLineItems[wo._id] && <OrderList items={wo.lines} />}
      </Paper>
    </div>
  );
};

const styles = () => ({
  alertContainer: {
    backgroundColor: '#f3f3f4',
    border: '2px solid #e74c3c',
    padding: '3px 5px',
    borderRadius: '5px',
    maxWidth: '400px',
  },

  alertMessage: {
    color: '#666',
    fontSize: '10px',
    fontWeight: 'bold',
  },

  highlight: {
    color: '#444',
    fontWeight: '700',
  },
});

export default withStyles(styles)(SummaryCard);

import { Button, Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';

import { Sync } from '@material-ui/icons';
import endpoints from '../../../constants/endpoints';
import { dateFormat } from '../../../util/date';
import { cleanObject } from '../../../util/object';
import { Loading } from '../../HourlyWos/components';
import Filters from '../components/DtFilters';
import SummaryLocationTab from './SummaryLocationTab';

const styles = () => ({
  status: {
    color: 'white',
    borderRadius: '4px',
    padding: '5px 10px',
    margin: '2px 0',
    display: 'inline-block',
  },
  statusCurrent: {
    backgroundColor: '#ffcc80',
  },
  statusError: {
    backgroundColor: '#ef5350',
  },
  statusSuccess: {
    backgroundColor: '#1c84c6',
  },
  statusDefault: {
    backgroundColor: '#efefef',
    color: 'black',
  },
  table: {
    '& th': {
      padding: 10,
    },
    '& td': {
      padding: 10,
    },
  },
  statusVal: {
    marginLeft: '4px',
    fontWeight: 'bold',
    fontSize: 12,
  },
  icon: { fontSize: 16, paddingBottom: 2 },
});

const INITIAL_FILTERS = {
  dc_id: [],
  pcr_id: [],
  driver_id: [],
  bkt_type: 'resp',
  req_dt_type: 'any',
  print_status: 'b',
  sched_dt_type: 'on',
  req_dt: new Date(),
  sched_dt: new Date(),
  req_end_dt: null,
  sched_end_dt: null,
};

function RoutingSummary(props) {
  const [wo, setWo] = useState([]);
  const [data, setData] = useState([]);
  const [isSaving, setIsSaving] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [recentMoveWo, setRecentMoveWo] = useState(true);
  const [filters, setFilters] = useState({ ...INITIAL_FILTERS });

  const fetchRouteSummary = async () => {
    const body = cleanObject({
      ...filters,
      req_dt: dateFormat(filters.req_dt),
      sched_dt: dateFormat(filters.sched_dt),
      sched_end_dt: dateFormat(filters.sched_end_dt),
      req_end_dt: dateFormat(filters.req_end_dt),
    });
    setIsLoading(true);
    try {
      const { bkt_sequence } = await window.sch.post(
        endpoints.routing_summary,
        {
          ...body,
        }
      );

      setData(bkt_sequence);
    } catch (err) {
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  const applyFilters = () => {
    fetchRouteSummary();
  };

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    fetchRouteSummary();
  }, []);
  /* eslint-enable react-hooks/exhaustive-deps */

  const saveChanges = async () => {
    const bktName =
      filters.bkt_type === 'routing' ? 'region' : filters.bkt_type;
    const data = wo.map((data) => ({
      wo_id: data.wo_id,
      new_tag: {
        tag_key: bktName,
        [bktName]: data.targetGroupId,
      },
      origin_tag: {
        region: data.movedItem.region,
        milkrun: data.movedItem.milkrun,
      },
    }));
    setIsSaving(true);
    try {
      await window.sch.post(endpoints.update_bucket_workorder, {
        workorders: data,
      });
      setRecentMoveWo(false);
    } catch (err) {
      console.log(err, 'err');
    } finally {
      setIsSaving(false);
    }
  };

  useEffect(() => {
    setWo([]);
  }, [filters.bkt_type]);

  return (
    <Grid>
      <Grid container spacing={16} justify="space-between">
        <Grid item xs={10}>
          <Filters
            filters={filters}
            isLoading={isLoading}
            setFilters={setFilters}
            applyFilters={applyFilters}
            initialFilters={INITIAL_FILTERS}
          />
        </Grid>

        <Grid
          item
          xs={2}
          style={{
            marginBottom: 20,
            alignSelf: 'end',
            transform: 'translateX(-175px)',
          }}>
          {['milkrun', 'routing'].includes(filters.bkt_type) &&
            wo[0]?.movedItem && (
              <Button
                size="small"
                color="inherit"
                variant="contained"
                disabled={isLoading || isSaving}
                onClick={saveChanges}
                style={{ backgroundColor: '#1AB394', color: 'white' }}>
                <Sync fontSize="small" style={{ marginRight: '5px' }} />
                Save Changes
              </Button>
            )}
        </Grid>
      </Grid>

      {isLoading ? (
        <Loading />
      ) : data.length ? (
        <SummaryLocationTab
          {...props}
          data={data}
          setWo={setWo}
          recentMoveWo={recentMoveWo}
        />
      ) : (
        <div
          style={{
            textAlign: 'center',
            marginTop: '20px',
            fontSize: '16px',
            color: '#757575',
            fontWeight: 'bold',
          }}>
          No data available
        </div>
      )}
    </Grid>
  );
}

export default withStyles(styles)(RoutingSummary);

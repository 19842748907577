export const PRINT_TYPE = [
  {
    id: 1,
    value: 'b',
    label: 'Printed and UnPrinted',
  },
  {
    id: 2,
    value: 'n',
    label: 'UnPrinted',
  },
  {
    id: 3,
    value: 'y',
    label: 'Printed',
  },
];

export const WO_KIND = [
  {
    id: 1,
    value: 'all',
    label: 'All',
  },
  {
    id: 2,
    value: 'manual_ticket',
    label: 'Manual Ticket',
  },
];

export const SCHEDULE_TYPE = [
  {
    id: 1,
    value: 'un',
    label: 'UnScheduled',
  },
  {
    id: 2,
    value: 'any',
    label: 'Scheduled (All Dates)',
  },
  {
    id: 3,
    value: 'any_or_un',
    label: 'Scheduled (All Dates) or Unscheduled',
  },
  {
    id: 4,
    value: 'on',
    label: 'Sched.Dt on',
  },
  {
    id: 5,
    value: 'on_or_before',
    label: 'Sched.Dt on or before',
  },
  {
    id: 6,
    value: 'on_or_after',
    label: 'Sched.Dt on or after',
  },
  {
    id: 7,
    value: 'between',
    label: 'Sched.Dt between',
  },
  {
    id: 8,
    value: 'pr',
    label: 'Pending Reschedule',
  },
  {
    id: 9,
    value: 'pc',
    label: 'Pending Cancellation',
  },
];

export const REQUEST_TYPE = [
  {
    id: 1,
    value: 'any',
    label: 'All Request Dates',
  },
  {
    id: 2,
    value: 'on',
    label: 'Req.Dt on',
  },
  {
    id: 3,
    value: 'on_or_before',
    label: 'Req.Dt on or before',
  },
  {
    id: 4,
    value: 'on_or_after',
    label: 'Req.Dt on or after',
  },
  {
    id: 5,
    value: 'between',
    label: 'Req.Dt between',
  },
];

export const SPECIAL_FILERS = [
  { value: 'telecare', label: 'Telecare Clone' },
  { value: 'allgood', label: 'All Good' },
  { value: 'allbad', label: 'All Bad' },
];

import { Grid } from '@material-ui/core';
import _ from 'lodash';
import { withStyles } from '@material-ui/core/styles';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { open_snack_ac } from '../../actions/snack.ac.js';
import TemplateList from './TemplateList.js';
import TemplateDetail from './TemplateDetail.js';
import {
  deleteTemplateMapping,
  fetchTemplateMappings,
  setTemplateActivation,
  upsertTemplateMapping,
} from './api.js';
import {
  close_confirmation_dialog_ac,
  open_confirmation_dialog_ac,
} from '../../actions/dialog.ac.js';

const sortTemplates = (list) =>
  list.sort((a, b) => {
    if (a.disabled === b.disabled) return 0; // Keep relative order
    return a.disabled ? 1 : -1; // Disabled items to the bottom
  });

class JotformLandingView extends React.Component {
  state = {
    selectedTemplate: null,
    isFetching: false,
    list: [],
    isSavingTemplate: false,
    isRemovingTemplate: false,
    isUpdatingTemplateActivation: false,
  };

  componentDidMount() {
    return this.fetchFormTemplates();
  }

  fetchFormTemplates = () => {
    fetchTemplateMappings({
      before: () => this.setState({ isFetching: true }),
      after: () => this.setState({ isFetching: false }),
      onSuccess: (data) => {
        this.setState({ list: sortTemplates(data) });
      },
      onError: (error) => {
        this.props.open_snack_ac({
          message: error.message || 'Unknown error',
          variant: 'error',
        });
      },
    });
  };

  addFormTemplate = (formId, callback) => {
    // Check if formId already exists
    if (this.state.list.find((template) => template.formId === formId)) {
      this.props.open_snack_ac({
        message: 'Template mapping already exists',
        variant: 'error',
      });

      return;
    }
    this.setState((state) => ({
      list: [
        {
          formId,
          formName: '',
          modelSource: 'WO',
          metaKey: '',
          modelMapping: {},
          defaultValueMapping: {},
          changed: true,
        },
        ...state.list,
      ],
    }));

    if (callback) {
      callback();
    }
  };

  saveFormTemplate = (form, callback) => {
    upsertTemplateMapping(form, {
      before: () => this.setState({ isSavingTemplate: true }),
      after: () => this.setState({ isSavingTemplate: false }),
      onSuccess: (updatedMapping) => {
        if (callback) {
          callback();
        }
        this.props.open_snack_ac({
          message: 'Template mapping saved successfully',
          variant: 'success',
        });

        this.setState((state) => ({
          list: sortTemplates(
            state.list.map((template) =>
              template.formId === updatedMapping.formId
                ? updatedMapping
                : template
            )
          ),
          selectedTemplate: updatedMapping,
        }));
      },
      onError: (error) => {
        this.props.open_snack_ac({
          message: error.message || 'Unknown error',
          variant: 'error',
        });
      },
    });
  };

  removeFormTemplate = (template, callback) => {
    if (!template._id) {
      this.setState((state) => ({
        list: state.list.filter((cT) => cT.formId !== template.formId),
        selectedTemplate: null,
      }));
      return;
    }
    this.props.open_confirmation_dialog_ac({
      title: 'Are you sure?',
      content: `This action cannot be undone!`,
      actions: [
        {
          label: 'Yes, Permanently Delete!',
          action: () => {
            deleteTemplateMapping(+template.formId, {
              before: () => this.setState({ isRemovingTemplate: true }),
              after: () => this.setState({ isRemovingTemplate: false }),
              onSuccess: (data) => {
                if (callback) {
                  callback();
                }
                this.props.open_snack_ac({
                  message: 'Template mapping removed successfully',
                  variant: 'success',
                });

                this.setState((state) => ({
                  list: state.list.filter(
                    (cT) => cT.formId !== template.formId
                  ),
                  selectedTemplate: null,
                }));
              },
              onError: (error) => {
                this.props.open_snack_ac({
                  message: error.message || 'Unknown error',
                  variant: 'error',
                });
              },
            });
            this.props.close_confirmation_dialog_ac();
            return true;
          },
        },
      ],
    });
  };

  manageFormTemplateActivation = (toUpTemplate, disable, callback) => {
    this.props.open_confirmation_dialog_ac({
      title: 'Are you sure?',
      content: disable
        ? 'This template will not be visible or accessible in the Delivery Track mobile app.'
        : 'This template will then be visible and accessible in the Delivery Track mobile app.',
      actions: [
        {
          label: `Yes, ${disable ? 'Disable' : 'Enable'}!`,
          action: () => {
            setTemplateActivation(+toUpTemplate.formId, disable, {
              before: () =>
                this.setState({ isUpdatingTemplateActivation: true }),
              after: () =>
                this.setState({ isUpdatingTemplateActivation: false }),
              onSuccess: (data) => {
                if (callback) {
                  callback();
                }
                this.props.open_snack_ac({
                  message: `Template mapping ${
                    disable ? 'disabled' : 'enabled'
                  } successfully`,
                  variant: 'success',
                });

                this.setState((state) => ({
                  list: sortTemplates(
                    state.list.map((template) =>
                      template.formId === toUpTemplate.formId
                        ? disable
                          ? { ...template, disabled: true }
                          : _.omit(template, 'disabled')
                        : template
                    )
                  ),
                  selectedTemplate: disable
                    ? {
                        ...state.selectedTemplate,
                        disabled: true,
                      }
                    : _.omit(state.selectedTemplate, 'disabled'),
                }));
              },
              onError: (error) => {
                this.props.open_snack_ac({
                  message: error.message || 'Unknown error',
                  variant: 'error',
                });
              },
            });
            this.props.close_confirmation_dialog_ac();
            return true;
          },
        },
      ],
    });
  };

  handleTemplateClick = (template) => {
    if (template.formId === this.state.selectedTemplate?.formId) {
      return;
    }

    this.setState({ selectedTemplate: template });
  };

  handleTemplateMappingSaveClick = (tpl) => {
    this.saveFormTemplate(tpl);
  };

  handleTemplateMappingChange = (formId, newDetail) => {
    this.setState((state) => ({
      ...state,
      selectedTemplate: {
        ...state.selectedTemplate,
        ...newDetail,
        changed: true,
      },
      list: state.list.map((template) => {
        if (template.formId === formId) {
          return { ...template, ...newDetail, changed: true };
        }
        return template;
      }),
    }));
  };

  render() {
    const {
      list,
      selectedTemplate,
      isFetching,
      isSavingTemplate,
      isRemovingTemplate,
      isUpdatingTemplateActivation,
    } = this.state;

    return (
      <Grid container style={{ marginTop: '20px' }}>
        <Grid
          item
          xs={3}
          style={{
            borderRight: '2px solid rgba(0, 0, 0, 0.12)',
            height: '85vh',
            overflowY: 'auto',
          }}>
          <TemplateList
            isFetching={isFetching}
            list={list}
            selectedTemplate={this.state.selectedTemplate}
            onTemplateClick={this.handleTemplateClick}
            onAddClick={this.addFormTemplate}
            isSavingTemplate={isSavingTemplate}
            openSnack={this.props.open_snack_ac}
          />
        </Grid>
        <Grid
          item
          xs={9}
          style={{
            borderRight: '2px solid rgba(0, 0, 0, 0.12)',
          }}>
          <TemplateDetail
            isFetching={isFetching}
            template={selectedTemplate}
            onRemoveClick={this.removeFormTemplate}
            onTemplateActivationUpdate={this.manageFormTemplateActivation}
            isRemovingTemplate={isRemovingTemplate}
            isUpdatingTemplateActivation={isUpdatingTemplateActivation}
            isSavingTemplate={isSavingTemplate}
            onChange={this.handleTemplateMappingChange}
            onSave={this.handleTemplateMappingSaveClick}
          />
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      open_snack_ac,
      open_confirmation_dialog_ac,
      close_confirmation_dialog_ac,
    },
    dispatch
  );

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles({})(JotformLandingView))
);

import { withStyles } from '@material-ui/core/styles';
import moment from 'moment';
import React from 'react';
import Timeline from '../WorkUnits/common/Timeline';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: 0,
    lineHeight: '1.42857143rem',
    boxSizing: 'border-box',
    '&:after, &:before': {
      boxSizing: 'border-box',
    },
    '&:after': {
      clear: 'both',
    },
    overflowY: 'auto',
  },
  row: {
    '&:before, &:after': {
      display: 'table',
      content: '" "',
    },
  },
  timeline: {
    minWidth: '400px',
    minHeight: '100px',
    position: 'relative',
    padding: '40px 20px',
    width: '85%',
    '&:before, &:after': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: '50%',
      transform: 'translateX(-50%)',
      background: '#2196f3',
    },
    '&:before': {
      height: '10px',
      width: '10px',
      borderRadius: '50%',
    },
    '&:after': {
      bottom: 0,
      width: '2px',
    },
  },
  mobileTimeline: {
    padding: '30px 15px',
    minWidth: '100%',
    '&:before, &:after': {
      left: '3%',
    },
  },
  timelineItem: {
    width: '100%',
    fontSize: '12px',
    margin: '20px 0px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexFlow: 'row nowrap',
    '& > *': {
      flex: '100px',
    },
    '&:last-child:after': {
      content: '""',
      position: 'absolute',
      bottom: 0,
      left: '50%',
      transform: 'translateX(-50%)',
      background: '#2196f3',
      height: '10px',
      width: '10px',
      borderRadius: '50%',
    },
  },
  eventContent: {
    color: '#424242',
    padding: '8px 6px',
    border: '1px solid grey',
    borderRadius: '6px',
  },
  timelineItemTitle: {
    fontSize: '13px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    color: 'navy',
  },
  timelineItemContent: { fontSize: '12px', marginTop: '6px', color: 'purple' },
  timelineItemFooter: {},
  timelineItemInfo: {
    color: '#b3b3b3',
  },
  timelineItemDate: {
    color: 'blue',
    textAlign: 'right',
    display: 'block',
  },
  timelineItemUser: {
    color: 'grey',
    fontSize: '10px',
    textAlign: 'right',
    display: 'block',
  },
  timelineItemSelector: {
    flex: '0 0 6px',
    padding: '6px',
    border: '1px solid #2196f3',
    borderRadius: '50%',
    margin: '0px 20px',
    background: '#fff',
    zIndex: 2,
    '&:hover': {
      transition: 'all 0.3s',
      background: '#2196f3',
      cursor: 'pointer',
    },
  },
  timelineBullets: {
    padding: 2,
    margin: '0 0 0 12px',
    fontSize: 11,
  },
  fireCount: {
    color: 'crimson',
    fontSize: '14px',
    marginRight: '4px',
  },
  noEvents: {
    color: 'grey',
    textAlign: 'center',
    fontSize: '14px',
  },
});

const PendingVerEventItem = (props) => {
  const { item } = props;
  return (
    <div>
      {item.new_consent && item.new_consent.invite_expires && (
        <small>
          Link(s) Expire:{' '}
          {moment(item.new_consent.invite_expires).format('MM-DD-YYYY hh:mm A')}
        </small>
      )}
    </div>
  );
};

const VerifyEventItem = (props) => {
  const { item } = props;
  return (
    <div style={{ backgroundColor: 'lightgreen' }}>
      Account Holder Verified {item.mode.toUpperCase()}:{item.u_id}
    </div>
  );
};
const FlavorChange = (props) => {
  const { item } = props;

  switch (item.to) {
    case 'declined_other_mode':
      break;
    case 'other_mode_pending_verification':
    default:
      break;
  }

  return (
    <div style={{ backgroundColor: 'lightgreen' }}>
      Still Verified - But changed from {item.from} to {item.to}
    </div>
  );
};

const ConsentHistory = (props) => {
  const { events, classes, mobileView } = props;

  const style = mobileView
    ? {}
    : { overflowY: 'auto', height: props.isModal ? '500px' : '85%' };

  return (
    <div className={classes.collapsableContainer} style={style}>
      <Timeline
        collapsable
        collapsed={mobileView}
        classes={classes}
        items={events}
        renderTitle={(item) => (
          <div className={classes.timelineItemDate}>
            {item.ts_str}
            <p className={classes.timelineItemUser}>{item.u_nm}</p>
          </div>
        )}
        renderDetail={(item, idx) => {
          // exmple of migration event =
          // {
          //   "_id" : ObjectId("66591e3e0d7f1e1a32837e1e"),
          //   "account" : 336216,
          //   "member" : 1,
          //   "event" : "migration",
          //   "migrated_state" : 4,
          //   "migrated_as" : {
          //     "mode_email" : {
          //       "value" : "",
          //       "consent" : false,
          //       "verified" : false,
          //       "invite_sent" : null,
          //       "invite_code" : null
          //     },
          //     "mode_sms" : {
          //       "value" : "951-663-7385",
          //       "consent" : true,
          //       "verified" : true,
          //       "invite_sent" : ISODate("2019-10-01T17:58:31.069Z"),
          //       "invite_code" : "legacy",
          //       "verified_date" : ISODate("2019-10-01T17:59:54.537Z")
          //     }
          //   }
          // }

          if (item.event === 'migration') {
            return (
              <div key={idx}>
                <span style={{ fontSize: 11 }}>
                  Migrated from Legacy Onboarding
                </span>
                <div>Status at time of Migration:</div>
                <div style={{ marginBottom: 8 }}>
                  {item.migrated_as.mode_email.consent ? (
                    <div>
                      <strong>Email:Subscribed</strong>:{' '}
                      {item.migrated_as.mode_email.value}
                      <br />
                      {moment(item.migrated_as.mode_email.verified_date).format(
                        'MM-DD-YYYY hh:mm A'
                      )}
                    </div>
                  ) : (
                    <span>Email: Does Not Consent</span>
                  )}
                </div>
                <div>
                  {item.migrated_as.mode_sms.consent ? (
                    <div>
                      <strong>SMS: Subscribed</strong>:{' '}
                      {item.migrated_as.mode_sms.value}
                      <br />
                      {moment(item.migrated_as.mode_sms.verified_date).format(
                        'MM-DD-YYYY hh:mm A'
                      )}
                    </div>
                  ) : (
                    <span>SMS: Does Not Cosent</span>
                  )}
                </div>
              </div>
            );
          }

          if (item.event === 'reminder_sent') {
            return (
              <div key={idx}>
                <span style={{ fontSize: 11 }}>
                  Verification reminder sent to {item.to}
                </span>
              </div>
            );
          }
          if (item.event === 'sms_stop') {
            return (
              <div>
                <strong>
                  Patient RESPONDED WITH 'STOP' from {item.number_stopped} to
                  SuperCare SMS Sending Number. Patient must send START to{' '}
                  {item.twilio_number} to receive future text messages.
                </strong>
              </div>
            );
          }
          if (item.event === 'sms_start') {
            return (
              <div>
                <strong>
                  Patient sent 'START' from {item.number_started} to SuperCare
                  SMS Sending Number: {item.twilio_number}. Patient should now
                  be able to receive future text messages to{' '}
                  {item.number_started}
                </strong>
              </div>
            );
          }

          return (
            <div key={idx}>
              {item.from !== item.to ? (
                <span style={{ fontSize: 11 }}>
                  CONSENT STATUS changed from {item.from} to {item.to}
                  {item.content}
                </span>
              ) : (
                <div>
                  <div style={{ fontSize: 11 }}>
                    No change in CONSENT STATUS - remains {item.to}
                  </div>
                  {item.new_consent && item.mode && (
                    <strong>
                      {item.mode.toUpperCase()} updated to:
                      {item.new_consent['mode_' + item.mode].consent
                        ? 'Subscribed'
                        : 'Does Not Consent'}
                    </strong>
                  )}
                </div>
              )}
              {item.note && (
                <div>
                  <strong>{item.note}</strong>
                </div>
              )}

              {item.also_stop_event && (
                <div>
                  <strong>
                    Patient RESPONDED WITH 'STOP' from{' '}
                    {item.new_consent.mode_sms.value} to our SuperCare SMS
                    Sending Number. Patient must send START to{' '}
                    {item.twilio_number} to receive future text messages.
                  </strong>
                </div>
              )}
              <div>
                {item.send_results &&
                  item.send_results.map((sr, idx) => {
                    return (
                      <div key={idx}>
                        {sr.success ? 'Sent' : 'Failed sending'} verification
                        link to: {sr.to}{' '}
                      </div>
                    );
                  })}
              </div>
              {item.flow === 'v' && (
                <div style={{ fontSize: 10 }}>
                  Workunit: V{item.flow_record_id}
                </div>
              )}
              {item.flow === 'wos' && (
                <div style={{ fontSize: 10 }}>
                  Workorder: {item.flow_record_id}
                </div>
              )}
              {item.reason && <div>Reason: {item.reason}</div>}
              {item.to === 'pending_verification' && (
                <PendingVerEventItem item={item} />
              )}
              {item.to === 'verified' && <VerifyEventItem item={item} />}
              {item.event === 'verified_flavor_change' && (
                <FlavorChange item={item} />
              )}
            </div>
          );
        }}
      />
    </div>
  );
};

export default withStyles(styles)(ConsentHistory);

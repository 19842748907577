import { Button, Grid, IconButton, TextField } from '@material-ui/core';
import React, { useEffect, useState } from 'react';

import { preventKeys } from '../../../util/string.js';
import CustomTable from '../../common/CustomTable.js';
import ShippingForm from './ShippingForm.js';
// import WoData from './WoData.js';

const BoxMan = (props) => {
  const {
    data,
    checkWo,
    updateWoField,
    validatePosInt,
    createShips,
    openSnack,
  } = props;
  const [scodes_inv] = useState({
    'Priority Overnight': 'PDS',
    'Early Priority Overnight': 'EPS',
    'Noon Priority Overnight': 'NPS',
    'Saturday Delivery': 'SDS',
    'Early Saturday Delivery': 'ESS',
    'GSO Ground': 'CPS',
  });
  const [scode_opts] = useState([
    'GSO Ground',
    'Priority Overnight',
    'Early Priority Overnight',
    'Noon Priority Overnight',
    'Saturday Delivery',
    'Early Saturday Delivery',
  ]);
  const [sig_opts] = useState([
    'SIG_NOT_REQD',
    'SIG_STANDARD',
    'SIG_REQD',
    'ADULT_SIG_REQD',
  ]);

  const [pkgs, setPkgs] = useState([]);
  const [lineKeys, setLineKeys] = useState({});
  const [currentLines, setCurrentLines] = useState([]);
  const [hasBeenClicked, setHasBeenClicked] = useState(false);

  useEffect(() => {
    setCurrentLines(data.lines);
  }, [data.lines]);

  const clearPkgLines = () => {
    const newPkgs = pkgs.map((pkg) => {
      return { ...pkg, lines: [] };
    });

    setPkgs(newPkgs);
  };

  const downBox = () => {
    let new_pkgs = [...pkgs];
    new_pkgs.pop();

    setPkgs(new_pkgs);
  };

  const updateLineQty = (val, line_index) => {
    let updated_lines = [...currentLines];
    updated_lines[line_index].box_qty = Number(val.target.value);
    setCurrentLines(updated_lines);
  };

  const validateLines = () => {
    if (pkgs.length === 0 || pkgs === undefined) {
      throw new Error('You cannot submit without packages');
    }

    let empty = [];
    let wrong = [];
    let prods = [];
    let updatedLines = [...currentLines];
    updatedLines.forEach((line) => {
      if (line.bucket > currentLines.length || line.bucket < 0) {
        wrong.push(line.product_code);
      }
      if (line.box_qty !== undefined && line.box_qty === 0) {
        throw new Error("You're shipping out a product with quantity 0");
      }
      if (line.box_qty === undefined) {
        line.box_qty = line.qty_ordered;
      }
      if (prods.indexOf(line.product_code) === -1) {
        prods.push(line.product_code);
      }
      if (line.bucket === undefined || line.bucket === null) {
        empty.push(line);
      } else {
        pkgs[line.bucket].lines.push(line);
      }
    });

    if (wrong.length > 0) {
      throw new Error('There are more boxes than lines');
    }

    pkgs.forEach((pkg) => {
      if (pkg.lines.length === 0) {
        throw new Error('Some boxes are empty');
      }
    });

    if (empty.length > 0) {
      throw new Error(
        'All line items need to be assigned to packages before submission'
      );
    }

    prods.forEach((prod) => {
      if (lineKeys[prod] !== undefined) {
        let prodCount = currentLines
          .filter((ls) => ls.product_code === prod)
          .map((lz) => lz.box_qty)
          .reduce((p, n) => p + n);
        let total = lineKeys[prod] - prodCount;
        if (total !== 0) {
          throw new Error(
            `The quantities of ${prod} don't sum to the total of ${lineKeys[prod]} there's ${total} missing`
          );
        }
      }
    });

    let val = { target: { value: updatedLines } };
    updateWoField(val, 'lines');
  };

  const validateValues = () => {
    if (pkgs.length === 0) {
      throw new Error('Require at least one Package before sending to GSO');
    }
    return;
    // let vals = pkgs.filter((pkg) => {
    //   let v = parseFloat(pkg.value, 10);
    //   return v > 0 && v < 200;
    // });
    // debugger;

    // if (vals.length > 0) {
    //   throw new Error(
    //     'we should only be insuring products or packages worth more than $200'
    //   );
    // }
  };

  const validateWeights = () => {
    if (pkgs.length === 0) {
      throw new Error('Require at least one Package before sending to GSO');
    }
    let vals = pkgs.filter((pkg) => {
      let w = parseFloat(pkg.weight, 10);
      return w < 0 || w > 150 || w === null;
    });
    if (vals.length > 0) {
      throw new Error('Invalid Package Weight');
    }
  };

  const shipIt = async () => {
    if (pkgs.length === 0 || pkgs === undefined) {
      return openSnack({
        variant: 'warning',
        message: 'Need at least one package to submit to GSO.',
      });
    }
    setHasBeenClicked(true);
    try {
      clearPkgLines();
      validateLines();
      validateValues();
      validateWeights();
      await createShips(pkgs);
    } catch (e) {
      setHasBeenClicked(false);
      openSnack({
        variant: 'error',
        message: e.message,
      });
    }
  };

  const splitLine = (curLine) => {
    if (
      (curLine.box_qty === undefined && curLine.qty_ordered <= 1) ||
      (curLine.box_qty !== undefined && curLine.box_qty <= 1)
    ) {
      openSnack({
        variant: 'warning',
        message: "can't split a line with quantity 1",
      });
      return;
    }
    let line = { ...curLine };
    if (lineKeys[line.product_code] === undefined) {
      setLineKeys({
        ...lineKeys,
        [line.product_code]: line.qty_ordered,
      });
    }
    let newLines = [...currentLines];
    line.box_qty = 0;
    newLines.push(line);
    if (
      newLines.filter((l) => l.product_code === line.product_code).length >
      lineKeys[line.product_code]
    ) {
      openSnack({
        variant: 'warning',
        message:
          "can't have more instances of a line than the original quantity",
      });

      return;
    }
    newLines.forEach((l) => {
      if (l.product_code === line.product_code) {
        l.has_been_split = true;
      }
    });
    setCurrentLines(newLines);
  };

  const upBox = () => {
    if (pkgs.length >= currentLines.length) {
      return openSnack({
        variant: 'warning',
        message: 'You cannot have more boxes than you have line items',
      });
    }
    let box_no = pkgs.length;
    let box = {
      _id: box_no,
      scode: 'CPS',
      weight: null,
      value: 0,
      sig: 'SIG_NOT_REQD',
      lines: [],
    };
    let new_pkgs = [...pkgs];
    new_pkgs.push(box);

    setPkgs(new_pkgs);
  };

  const updatePackageProperty = (index, property, value) => {
    setPkgs((prevPkgs) =>
      prevPkgs.map((pkg, i) =>
        i === index ? { ...pkg, [property]: value } : pkg
      )
    );
  };

  const columns = [
    {
      title: 'Product Code',
      dataIndex: 'product_code',
      key: 'product_code',
      width: '15%',
    },

    {
      title: 'Product Description',
      dataIndex: 'product_desc',
      key: 'product_desc',
    },

    {
      title: 'Qty',
      dataIndex: 'line',
      key: 'line',
      width: '10%',
      render: (line, i) => (
        <>
          {line.has_been_split === true ? (
            <TextField
              type="number"
              id={'line_qty-' + i.toString()}
              value={line.box_qty}
              onKeyDown={(e) => preventKeys(e)}
              onChange={(val) => {
                updateLineQty(val, i);
              }}
              style={{ width: 30 }}
            />
          ) : (
            <div>{line.qty_ordered}</div>
          )}
        </>
      ),
    },

    {
      title: 'Notes',
      dataIndex: 'line_note',
      key: 'line_note',
    },
    {
      title: 'Box#',
      dataIndex: 'box_number',
      key: 'box_number',
      width: '10%',
      render: (line, i) => (
        <TextField
          id={'bucket-' + i.toString()}
          type="number"
          onKeyDown={(e) => preventKeys(e)}
          onChange={(val) => {
            line.bucket = val.target.value - 1;
          }}
          style={{ width: 30 }}
        />
      ),
    },

    {
      title: '',
      dataIndex: 'icon',
      key: 'icon',
      width: '5%',
      render: (line) => (
        <IconButton
          tabindex="-1"
          onClick={(val) => {
            splitLine(line);
          }}
          style={{ padding: 0 }}>
          <i className="material-icons">add</i>
        </IconButton>
      ),
    },
  ];

  return (
    <Grid container direction="row">
      <Grid item xs={5} style={{ height: '800px', overflowY: 'auto' }}>
        {/* <WoData
          wos_doc={data}
          updateWoField={updateWoField}
          validatePosInt={validatePosInt}
        /> */}
        <div style={{ marginTop: 12, marginBottom: 12 }}>
          <TextField
            id="wo"
            label="Lookup another WO#"
            margin="dense"
            onKeyUp={checkWo}
            onChange={validatePosInt}
            placeholder="Work Order Number"
          />
        </div>

        <ShippingForm
          wos_doc={data}
          updateWoField={updateWoField}
          validatePosInt={validatePosInt}
          upBox={upBox}
          downBox={downBox}
        />

        <div>
          {pkgs.map((pkg, i) => {
            return (
              <Grid
                key={i}
                container
                spacing={8}
                direction="column"
                style={{
                  marginTop: 12,
                  padding: '12px',
                  background: '#eeeeee',
                  marginBottom: '10px',
                }}>
                <Grid item>Box Number {i + 1}</Grid>
                <Grid item xs={12}>
                  <select
                    id="service"
                    onChange={(e) => {
                      updatePackageProperty(i, 'scode', e.target.value);
                    }}
                    style={{ width: '100%', padding: '5px 10px' }}>
                    {scode_opts.map((scode, j) => {
                      return (
                        <option value={scodes_inv[scode]} key={j}>
                          {scode}
                        </option>
                      );
                    })}
                  </select>
                </Grid>
                <Grid item xs={12}>
                  <select
                    id="sign"
                    onChange={(e) => {
                      updatePackageProperty(i, 'sig', e.target.value);
                    }}
                    style={{ width: '100%', padding: '5px 10px' }}>
                    {sig_opts.map((sig, j) => {
                      return (
                        <option value={sig} key={j}>
                          {sig}
                        </option>
                      );
                    })}
                  </select>
                </Grid>
                <Grid item>
                  <Grid container direction="row" spacing={8}>
                    <Grid item sm={6}>
                      <TextField
                        label="Weight"
                        id="weight"
                        type="number"
                        onKeyDown={(e) => preventKeys(e)}
                        onChange={(e) => {
                          const val = Math.round(e.target.value);
                          updatePackageProperty(i, 'weight', val);
                        }}
                      />
                    </Grid>

                    <Grid item sm={6}>
                      <TextField
                        id="value"
                        type="number"
                        label="Value"
                        onKeyDown={(e) => preventKeys(e)}
                        onChange={(e) => {
                          updatePackageProperty(i, 'value', e.target.value);
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            );
          })}
        </div>
      </Grid>
      <Grid item xs={7}>
        <CustomTable
          columns={columns}
          hoverEffect={false}
          data={currentLines}
          isLoading={!currentLines.length}
          baseStyle={{ height: 'fit-content' }}
          error={{ isError: 'error', message: 'There is no Line items' }}
        />

        <Button
          color="primary"
          onClick={shipIt}
          variant="contained"
          disabled={hasBeenClicked}
          style={{ float: 'right', marginTop: 20 }}>
          Ship Them
        </Button>
      </Grid>
    </Grid>
  );
};

export default BoxMan;

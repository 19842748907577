import {
  Grid,
  IconButton,
  MenuItem,
  TextField,
  Typography,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { AiOutlineDelete, AiOutlineEdit, AiOutlineSave } from 'react-icons/ai';

const DraggableGridRow = ({
  row,
  classes,
  disabled,
  handleCellChange,
  onSave,
  onDelete,
}) => {
  const [editing, setEditing] = useState(false);

  useEffect(() => {
    if (!row._id) {
      setEditing(true);
    }
  }, [row._id]);

  const readonlyText = (txt, moreStyles = {}) => {
    return (
      <Typography
        style={{
          fontSize: 14,
          ...moreStyles,
        }}
        variant="body2">
        {txt}
      </Typography>
    );
  };

  return (
    <div
      style={{
        transition: 'height 0.5s ease',
        border: !row._id // New Row
          ? '2px solid darkseagreen'
          : editing
          ? '1px solid purple'
          : '1px solid rgba(0, 0, 0, 0.12)',
        ...(row.changed
          ? {
              border: '1px solid green',
              background: 'lightgoldenrodyellow',
            }
          : {}),
        padding: '10px',
        marginBottom: '10px',
      }}>
      <Grid container spacing={2} alignItems="center">
        {/* Precedence */}
        <Grid item xs={1} className={classes.textFieldContainer}>
          {editing ? (
            <TextField
              disabled={disabled}
              type="number"
              value={row.precedence}
              onChange={(e) =>
                handleCellChange(row.id, 'precedence', +e.target.value)
              }
              style={{ fontSize: 14, color: 'blue' }}
              InputProps={{
                inputProps: { min: 1, step: 1 },
              }}
            />
          ) : (
            readonlyText(row.precedence, {
              fontWeight: 'bold',
            })
          )}
        </Grid>

        {/* Alias */}
        <Grid item xs={2} className={classes.textFieldContainer}>
          {editing ? (
            <TextField
              value={row.alias || ''}
              disabled={disabled}
              onChange={(e) =>
                handleCellChange(row.id, 'alias', e.target.value)
              }
              fullWidth
              style={{ fontSize: 14 }}
            />
          ) : (
            readonlyText(row.alias)
          )}
        </Grid>

        {/* Role */}
        <Grid item xs={2} className={classes.textFieldContainer}>
          {editing ? (
            <TextField
              value={row.role}
              disabled={disabled}
              onChange={(e) => handleCellChange(row.id, 'role', e.target.value)}
              fullWidth
              style={{ fontSize: 14 }}
            />
          ) : (
            readonlyText(row.role)
          )}
        </Grid>

        {/* Route */}
        <Grid item xs={3} className={classes.textFieldContainer}>
          {editing ? (
            <TextField
              value={row.route}
              disabled={disabled}
              onChange={(e) =>
                handleCellChange(row.id, 'route', e.target.value)
              }
              fullWidth
              style={{ fontSize: 14, color: 'blue' }}
            />
          ) : (
            readonlyText(row.route)
          )}
        </Grid>

        {/* Action */}
        <Grid item xs={2} className={classes.textFieldContainer}>
          {editing ? (
            <TextField
              select
              disabled={disabled}
              value={row.action}
              style={{
                fontSize: 14,
                color: row.action === 'allow' ? 'green' : 'red',
              }}
              onChange={(e) =>
                handleCellChange(row.id, 'action', e.target.value)
              }>
              {' '}
              <MenuItem
                style={{
                  color: 'green',
                }}
                value="allow">
                Allow
              </MenuItem>
              <MenuItem
                style={{
                  color: 'red',
                }}
                value="deny">
                Deny
              </MenuItem>
            </TextField>
          ) : (
            readonlyText(row.action, {
              color: row.action === 'allow' ? 'green' : 'red',
            })
          )}
        </Grid>

        {/* Control Buttons */}
        <Grid
          item
          xs={2}
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          <IconButton
            title="Edit"
            aria-label={editing ? 'Save' : 'Edit'}
            disabled={disabled}
            onClick={() => {
              if (editing) {
                onSave(row, () => {
                  setEditing(false);
                });
                return;
              }
              setEditing(true);
            }}
            style={{ padding: 2, marginLeft: '4px' }}>
            {editing ? (
              <AiOutlineSave style={{ height: 22, width: 22 }} />
            ) : (
              <AiOutlineEdit style={{ height: 22, width: 22 }} />
            )}
          </IconButton>

          <IconButton
            title="Delete"
            aria-label="Delete"
            disabled={disabled}
            onClick={() => {
              onDelete(row);
            }}
            style={{ padding: 2, marginLeft: '4px' }}>
            <AiOutlineDelete style={{ height: 22, width: 22 }} />
          </IconButton>
        </Grid>
      </Grid>
    </div>
  );
};

export default DraggableGridRow;

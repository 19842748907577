import {
  Button,
  Divider,
  Input,
  List,
  ListItem,
  Typography,
} from '@material-ui/core';
import React from 'react';
import { Loading } from '../HourlyWos/components';

const TemplateList = ({
  isFetching = false,
  list,
  onTemplateClick,
  openSnack,
  selectedTemplate,
  onAddClick,
  isSavingTemplate = false,
}) => {
  const [draftTemplate, setDraftTemplate] = React.useState('');

  const handleAddClick = () => {
    if (!draftTemplate || draftTemplate.length < 3) {
      openSnack({
        message: 'Template ID must be at least 3 characters long',
        variant: 'error',
      });
      return;
    }

    if (isNaN(draftTemplate)) {
      openSnack({
        message: 'Template ID must be a number',
        variant: 'error',
      });
      return;
    }

    onAddClick(Number(draftTemplate), () => setDraftTemplate(''));
  };

  const onTemplateDraftChange = (e) => {
    setDraftTemplate(e.target.value);
  };

  return (
    <>
      <div
        style={{
          marginBottom: 8,
          marginLeft: 8,
          display: 'flex',
          justifyContent: 'left',
        }}>
        <Input
          variant="contained"
          placeholder={'Add a new template'}
          type="text"
          disabled={isFetching || isSavingTemplate}
          fullWidth
          value={draftTemplate}
          style={{ width: '80%' }}
          onChange={onTemplateDraftChange}
        />
        <Button
          disabled={isFetching || isSavingTemplate}
          style={{ marginLeft: '8px' }}
          variant="outlined"
          size="small"
          color="primary"
          onClick={handleAddClick}>
          {isSavingTemplate ? '...' : 'Add'}
        </Button>
      </div>
      <Divider component="hr" />
      {isFetching ? (
        <Loading />
      ) : list?.length ? (
        <List style={{ paddingTop: '0px' }}>
          {list.map((template, index) => (
            <div key={index}>
              <ListItem
                style={{
                  ...(template.disabled
                    ? {
                        opacity: 0.9,
                        color: 'darkgrey',
                      }
                    : {}),
                  display: 'flex',
                  justifyContent: 'left',
                  cursor: 'default',
                  background: template.changed ? 'lightyellow' : 'white',
                }}>
                <Typography
                  variant="body1"
                  color={'textPrimary'}
                  onClick={() => {
                    onTemplateClick(template);
                  }}
                  style={{
                    color: template.disabled ? '#999' : '#000',
                    fontSize: '11px',
                    cursor: 'pointer',
                    textDecoration:
                      template.formId === selectedTemplate?.formId
                        ? 'underline'
                        : 'none',
                    fontWeight:
                      template.formId === selectedTemplate?.formId
                        ? 'bold'
                        : 'normal',
                  }}>
                  {template.formName || template.formId}
                  {template.disabled ? ' (Disabled)' : ''}
                </Typography>
              </ListItem>{' '}
              <Divider component="hr" style={{ margin: '0px 8px' }} />
            </div>
          ))}
        </List>
      ) : (
        <span style={{ fontSize: 10 }} value={'No Entries'} />
      )}{' '}
    </>
  );
};

export default TemplateList;

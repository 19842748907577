import { Button, Grid, TextField } from '@material-ui/core';
import React, { useEffect, useState } from 'react';

import { PRINT_TYPE, REQUEST_TYPE, SCHEDULE_TYPE } from '../../../constants/dt';
import endpoints from '../../../constants/endpoints';
import CustomSelect from '../../common/CustomSelect';
import ReactSelect from '../../common/ReactSelect';
import SuperReactDatePicker from '../../common/SuperReactDatePicker';

const showDtFilter = ['on', 'on_or_before', 'on_or_after', 'between'];

const BUCKET_LIST = [
  {
    label: 'None',
    value: 'none',
  },
  {
    label: 'Resp',
    value: 'resp',
  },
  {
    label: 'MilkRun',
    value: 'milkrun',
  },
  {
    label: 'Regional',
    value: 'routing',
  },
  {
    label: 'Assigned and Routed',
    value: 'assigned',
  },
];

const DtFilters = ({
  applyFilters,
  isLoading,
  setFilters,
  filters,
  initialFilters,
}) => {
  const [pcr, setPcr] = useState([]);
  const [drivers, setDrivers] = useState([]);
  const [location, setLocation] = useState([]);
  const handleFilterFieldChange = (name, value) => {
    setFilters((filters) => ({
      ...filters,
      [name]: value,
    }));
  };

  const fetchLocation = async () => {
    try {
      const { place } = await window.sch.get(endpoints.location);
      const data = place.map((loc) => ({
        value: loc.dc_id,
        label: loc.dc_nm,
      }));
      setLocation(data);
    } catch (err) {
      console.log(err);
    }
  };

  const fetchPcr = async () => {
    try {
      const { rr } = await window.sch.get(endpoints.pcr);
      const data = rr.map((pcr) => ({
        value: pcr.hdms_id,
        label: pcr.nm,
      }));
      setPcr(data);
    } catch (err) {
      console.log(err);
    }
  };

  const fetchDrivers = async () => {
    try {
      const { rr } = await window.sch.get(endpoints.drivers);
      const data = rr.map((driver) => ({
        value: driver.driver_id,
        label: driver.driver_nm,
      }));
      setDrivers(data);
    } catch (err) {
      console.log(err);
    }
  };

  const handleDateChange = (name, value) => {
    setFilters((filters) => ({
      ...filters,
      [name]: value,
    }));
  };

  const handleClearFilters = () => {
    setFilters({ ...initialFilters });
  };

  useEffect(() => {
    fetchPcr();
    fetchDrivers();
    fetchLocation();
  }, []);

  return (
    <Grid
      container
      spacing={16}
      alignItems="center"
      style={{ padding: '20px 0', alignItems: 'end' }}>
      <Grid
        container
        spacing={16}
        alignItems="center"
        style={{ paddingLeft: '5px' }}>
        <Grid item xs={2}>
          <CustomSelect
            name="print_status"
            options={PRINT_TYPE}
            label="Print Status"
            onChange={(e) => {
              const { name, value } = e.target;
              handleFilterFieldChange(name, value);
            }}
            value={filters.print_status}
          />
        </Grid>

        <Grid item xs={2}>
          <CustomSelect
            name="req_dt_type"
            options={REQUEST_TYPE}
            label="Request Date"
            onChange={(e) => {
              const { name, value } = e.target;
              handleFilterFieldChange(name, value);
            }}
            value={filters.req_dt_type}
          />
        </Grid>

        {showDtFilter.includes(filters.req_dt_type) && (
          <Grid item xs={1}>
            <SuperReactDatePicker
              selected={filters.req_dt}
              dateFormat="MM-dd-yyyy"
              onChange={(date) => handleDateChange('req_dt', date)}
              customInput={<TextField margin="dense" label="Request From" />}
            />
          </Grid>
        )}

        {filters.req_dt_type === 'between' && (
          <Grid item xs={1}>
            <SuperReactDatePicker
              selected={filters.req_end_dt || new Date()}
              dateFormat="MM-dd-yyyy"
              onChange={(date) => handleDateChange('req_end_dt', date)}
              customInput={<TextField margin="dense" label="Request To" />}
            />
          </Grid>
        )}

        <Grid item xs={2}>
          <CustomSelect
            name="sched_dt_type"
            options={SCHEDULE_TYPE}
            label="Schedule Date"
            onChange={(e) => {
              const { name, value } = e.target;
              handleFilterFieldChange(name, value);
            }}
            value={filters.sched_dt_type}
          />
        </Grid>

        {showDtFilter.includes(filters.sched_dt_type) && (
          <Grid item xs={2}>
            <SuperReactDatePicker
              selected={filters.sched_dt}
              dateFormat="MM-dd-yyyy"
              onChange={(date) => handleDateChange('sched_dt', date)}
              customInput={<TextField margin="dense" label="Scheduled From" />}
            />
          </Grid>
        )}

        {filters.sched_dt_type === 'between' && (
          <Grid item xs={2}>
            <SuperReactDatePicker
              selected={filters.sched_end_dt}
              dateFormat="MM-dd-yyyy"
              onChange={(date) => handleDateChange('sched_end_dt', date)}
              customInput={<TextField margin="dense" label="Scheduled To" />}
            />
          </Grid>
        )}
      </Grid>

      <Grid item xs={2}>
        <ReactSelect
          isMulti
          name="dc_id"
          label="Location"
          options={location}
          value={filters.dc_id}
          placeholder="Select Location"
          onChange={(value, e) => {
            const name = e.name;
            handleFilterFieldChange(name, value);
          }}
        />
      </Grid>
      <Grid item xs={2}>
        <ReactSelect
          isMulti
          name="driver_id"
          options={drivers}
          label="Assigned Drivers"
          value={filters.driver_id}
          placeholder="Select Drivers"
          onChange={(value, e) => {
            const name = e.name;
            handleFilterFieldChange(name, value);
          }}
        />
      </Grid>
      <Grid item xs={2}>
        <ReactSelect
          isMulti
          label="PCR"
          name="pcr_id"
          options={pcr}
          value={filters.pcr_id}
          placeholder="Select PCR"
          onChange={(value, e) => {
            const name = e.name;
            handleFilterFieldChange(name, value);
          }}
        />
      </Grid>
      {filters.bkt_type && (
        <Grid item xs={2}>
          <CustomSelect
            name="bkt_type"
            options={BUCKET_LIST}
            label="Bucket Type"
            onChange={(e) => {
              const { name, value } = e.target;
              handleFilterFieldChange(name, value);
            }}
            value={filters.bkt_type}
          />
        </Grid>
      )}
      <Grid item>
        <Button
          size="small"
          color="primary"
          variant="contained"
          onClick={applyFilters}
          disabled={isLoading}>
          Show Records
        </Button>
      </Grid>
      <Grid item>
        <Button
          size="small"
          color="secondary"
          variant="contained"
          onClick={handleClearFilters}
          disabled={isLoading}>
          Clear All
        </Button>
      </Grid>
    </Grid>
  );
};

export default DtFilters;
